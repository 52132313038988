import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'

export class ChapterReaderType {
  @Expose() id: number

  @Expose({ name: 'runningNo' }) order: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() viewCount: number

  @Expose() commentCount: number

  @Expose() price: number

  @Expose() status: ChapterStatusEnum

  @Expose() createdAt: string

  @Expose() updatedAt: string

  @Expose() publishedAt: string

  @Expose()
  @Transform(({ obj }) => {
    if (!obj.publishedAt) return obj.updatedAt

    return DateTime.fromISO(obj.updatedAt)
      .diff(DateTime.fromISO(obj.publishedAt))
      .toMillis() > 0
      ? obj.updatedAt
      : obj.publishedAt
  })
  updatedAtOrPublishedAt: string

  @Expose() freeReadEndTime: string

  @Expose() chapterCoverImgPath: string

  @Expose() isPaid: boolean

  @Expose() isBookmarked: boolean
}
