import { Expose } from 'class-transformer'

export class BuyAllBookChapterReaderType {
  @Expose() id: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() price: number
}
