import { gql } from 'graphql-request'

export const INCREMENT_VIEW = gql`
  mutation IncrementView($bookId: Int, $bookChapterId: Int, $ebookId: Int) {
    incrementView(
      bookId: $bookId
      bookChapterId: $bookChapterId
      ebookId: $ebookId
    )
  }
`
