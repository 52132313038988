import { Expose } from 'class-transformer'

import { PromotionBuyAllEnum } from '@interfaces/PromotionTypeEnum'

export class BookBuyAllPromotionType {
  @Expose() id: number

  @Expose() title: string

  @Expose() startDate: string

  @Expose() endDate: string

  @Expose() discountType: PromotionBuyAllEnum

  @Expose() discountValue: number

  @Expose() discountPrice: number

  @Expose() afterDiscountPrice: number

  @Expose() summaryPriceBeforeDiscount: number

  @Expose() bookChapterCount: number
}
