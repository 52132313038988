import { Expose } from 'class-transformer'

import { ReadPromotionStatusEnum } from '@interfaces/ReadPromotionStatusEnum'
import { PromotionReadFreeEnum } from '@interfaces/PromotionTypeEnum'

export class BookReadFreePromotionType {
  @Expose() id: number

  @Expose() status: ReadPromotionStatusEnum

  @Expose() readPromotionType: PromotionReadFreeEnum

  @Expose() readFreePerDay: number

  @Expose() readFreeWithinDay: number

  @Expose() readFreeIntervalEnd: string

  @Expose() readFreeIntervalStart: string

  @Expose() startDate: string

  @Expose() endDate: string

  @Expose() createdAt: string

  @Expose() updatedAt: string
}
