import { gql } from 'graphql-request'

export const GET_BOOK_READ_PROMOTION_DETAIL = gql`
  query GetBookReadPromotionDetail($bookId: Int!) {
    getBookReadPromotionDetail(bookId: $bookId) {
      todayReadCount
      alreadyReadChapter {
        chapterId
        createdAt
        expiredAt
      }
      currentReadPromotion {
        id
        status
        readPromotionType
        readFreePerDay
        readFreeWithinDay
        readFreeIntervalEnd
        readFreeIntervalStart
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`
