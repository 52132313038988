import { gql } from 'graphql-request'

export const CREATE_USER_READINGS = gql`
  mutation CreateUserReadings($createUserReadingInput: [UserReadingInput!]!) {
    createUserReadings(createUserReadingInput: $createUserReadingInput) {
      id
      updatedAt
    }
  }
`
