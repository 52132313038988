/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useContext,
  useEffect,
  useState,
  SetStateAction,
  useRef,
} from 'react'
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  linkWithPopup,
  updatePassword,
  unlink,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
  updateEmail,
  linkWithCredential,
  OAuthCredential,
  fetchSignInMethodsForEmail,
} from '@firebase/auth'
import { useRouter } from 'next/router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  firebase,
  registerMessagingSW,
  unregisterMessagingSW,
} from '@lib/firebase'

import { FunctionComponentType } from '@interfaces/commons/FunctionComponent'
import { LoginFormType } from '@features/authentication/components/LoginModal/interface'
import { RegisterFormType } from '@models/user/RegisterFormType'
import { UserType } from '@models/user/UserType'
import {
  ProviderDataEnum,
  providerDataValue,
} from '@interfaces/ProviderDataEnum'
import { DeviceType } from '@models/device/DeviceType'
import { FCM_KEY } from '@configs/firebase'
import { defaultUser } from '@constants/defaultUser'
import {
  BASE_URL,
  LINE_AUTH_URL,
  LINE_ID,
  NAIIN_APP_CODE,
  NAIIN_AUTH_URL,
  REGISTER_CONVERSION,
} from '@configs/config'
import { defaultUserCoin } from '@constants/defaultUserCoin'
import { CoinTypeEnum } from '@interfaces/CoinTypeEnum'
import { UserCoin } from '@models/user/UserCoin'
import { SocialSignInCredential } from '@interfaces/SocialSignCredential'
import { SignInWithSocialNetworkServiceFormType } from '@interfaces/SignInWithSocialNetworkServiceFormType'
import { event } from '@lib/gtag'
import { EVENT_ACTION_ENUM } from '@configs/config'
import { UserFirstLoginProviderEnum } from '@interfaces/UserFirstLoginProviderEnum'
import { fbqRegisterComplete } from '@lib/facebookPixel'
import { useClient } from './useClient'
import { useAlert } from './useAlert'
import { useUserAction } from './user/useUserAction'
import { useDeviceAction } from './device/useDeviceAction'
import { useAuthenticationAction } from './authentication/useAuthenticationAction'
import { usePaymentAction } from './payment/usePaymentAction'
import { useModal } from './contexts/ModalContext/ModalContext'

interface AuthenticationContext {
  user: UserType
  userCoin: UserCoin
  providerData: ProviderDataEnum[]
  isAuthenticated: boolean
  isUserLoading: boolean
  isLogin: boolean
  isWriter: boolean
  isLinkSocialMediaLoading: boolean
  token?: string
  devices: DeviceType[]
  canFetchApi: boolean
  providers: Record<string, any>
  showLimitAuthentication: boolean
  signIn: (user: LoginFormType) => void
  signOut: () => Promise<void>
  linkAccountWithSavedCredential: (
    provider: ProviderDataEnum,
    savedCredential: OAuthCredential
  ) => Promise<void>
  signInWithSocialMediaAndLinkAccount: (
    provider: ProviderDataEnum,
    savedCredential: OAuthCredential
  ) => Promise<SocialSignInCredential | null>
  signInWithSocialMedia: (
    provider: ProviderDataEnum
  ) => Promise<SocialSignInCredential>
  signInDevice: () => void
  register: (form: RegisterFormType) => Promise<void>
  forgotPassword: (email: string) => void
  resetPassword: (code: string, password: string) => void
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>
  handleLinkSocialMedia: (value: {
    value: boolean
    provider: ProviderDataEnum
  }) => Promise<void>
  deleteFirebaseAccount: () => Promise<void>
  setDevices: (value: SetStateAction<DeviceType[]>) => void
  setIsSignInLoading: (value: SetStateAction<boolean>) => void
  customTokenSignIn: (customToken: string) => Promise<SocialSignInCredential>
  linkWithLine: (idToken: string) => Promise<void>
  linkWithNaiin: (idToken: string) => Promise<void>
  requestFCMToken: () => Promise<string | undefined>
  forceRefreshToken: () => Promise<void>
  refetchMyProfile: () => Promise<void>
  redirectToLineAuthUrl: (mode: 'link' | 'login') => void
  redirectToNaiinAuthUrl: (mode: 'link' | 'login') => void
  signInWithSocialNetworkService: (
    form: SignInWithSocialNetworkServiceFormType
  ) => Promise<void>
  updateFirebaseEmail: (email: string) => Promise<void>
  reloadUser: () => Promise<void>
  setDeviceSignedIn: (value: boolean) => void
  setShowLimitAuthentication: (value: SetStateAction<boolean>) => void
  refetchUserCoin: () => Promise<void>
  handleAccountExistFor3rdPartySignIn: (
    token: string,
    provider: ProviderDataEnum
  ) => Promise<
    | {
        provider: ProviderDataEnum
        providerId: ProviderDataEnum
        token: string
        email: string
      }
    | undefined
  >
  signInDeviceWithCustomToken: (
    token: string,
    provider: ProviderDataEnum
  ) => Promise<void>
  callbackHandleAccountExistFor3rdPartySignIn: (modal: {
    confirmModal: ReturnType<typeof useModal>
    loginModal: ReturnType<typeof useModal>
  }) => void
}

const AuthenticateContext = createContext<AuthenticationContext>({
  user: defaultUser,
  userCoin: defaultUserCoin,
  providerData: [],
  isAuthenticated: false,
  isUserLoading: true,
  isLogin: false,
  isWriter: false,
  isLinkSocialMediaLoading: false,
  token: undefined,
  devices: [],
  canFetchApi: false,
  providers: {},
  showLimitAuthentication: false,
  signIn: () => {},
  signOut: async () => {},
  linkAccountWithSavedCredential: async () => {},
  signInWithSocialMediaAndLinkAccount: async () => {
    return Promise.resolve({ token: '', email: '' })
  },
  signInWithSocialMedia: async () => {
    return Promise.resolve({ token: '', email: '' })
  },
  signInDevice: () => {},
  register: async (form: RegisterFormType) => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  changePassword: async (_: string) => {},
  handleLinkSocialMedia: async (value: {
    value: boolean
    provider: ProviderDataEnum
  }) => {},
  deleteFirebaseAccount: async () => {},
  setDevices: () => {},
  setIsSignInLoading: () => {},
  customTokenSignIn: async () => {
    return Promise.resolve({ token: '', email: '' })
  },
  linkWithLine: async () => {},
  linkWithNaiin: async () => {},
  requestFCMToken: async () => undefined,
  forceRefreshToken: async () => {},
  refetchMyProfile: async () => {},
  redirectToLineAuthUrl: (mode: 'link' | 'login') => {},
  redirectToNaiinAuthUrl: (mode: 'link' | 'login') => {},
  signInWithSocialNetworkService: async (
    form: SignInWithSocialNetworkServiceFormType
  ) => {},
  updateFirebaseEmail: async (email: string) => {},
  reloadUser: async () => {},
  setDeviceSignedIn: (value: boolean) => {},
  setShowLimitAuthentication: (value: SetStateAction<boolean>) => {},
  refetchUserCoin: async () => {},
  handleAccountExistFor3rdPartySignIn: async () => {
    return Promise.resolve({
      provider: ProviderDataEnum.NAIIN,
      providerId: ProviderDataEnum.NAIIN,
      token: '',
      email: '',
    })
  },
  signInDeviceWithCustomToken: async () => {},
  callbackHandleAccountExistFor3rdPartySignIn: () => {},
})

const facebookProvider = new FacebookAuthProvider()
facebookProvider.addScope('user_birthday')
facebookProvider.addScope('user_gender')
facebookProvider.setCustomParameters({
  display: 'popup',
})

const googleProvider = new GoogleAuthProvider()
googleProvider.setCustomParameters({
  display: 'popup',
})

const appleProvider = new OAuthProvider('apple.com')
appleProvider.setCustomParameters({ display: 'popup' })

const providers = {
  [`${facebookProvider.providerId}`]: facebookProvider,
  [`${googleProvider.providerId}`]: googleProvider,
  [`${appleProvider.providerId}`]: appleProvider,
}

const auth = getAuth()
auth.languageCode = 'th'

export function AuthenticateProvider({ children }: FunctionComponentType) {
  const userRef = useRef<UserType>()
  const signInProviderRef = useRef<UserFirstLoginProviderEnum>()
  const [isFirebaseLoading, setIsFirebaseLoading] = useState(true)
  const [isSignInLoading, setIsSignInLoading] = useState(false)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [providerData, setProviderData] = useState<ProviderDataEnum[]>([])
  const [devices, setDevices] = useState<DeviceType[]>([])
  const [userCoin, setUserCoin] = useState<UserCoin>()
  const [isDeviceSignedIn, setIsDeviceSignedIn] = useState<boolean>(
    () =>
      typeof window !== 'undefined' &&
      localStorage.getItem('isDeviceSignedIn') === 'true'
  )
  const [showLimitAuthentication, setShowLimitAuthentication] = useState(false)
  const alert = useAlert()
  const client = useClient()
  const paymentClient = usePaymentAction()
  const userClient = useUserAction()
  const deviceClient = useDeviceAction()
  const authenticationClient = useAuthenticationAction()
  const queryClient = useQueryClient()

  const user = useQuery(
    'my-profile',
    () =>
      userClient.getProfile().then(res => {
        userRef.current = res
        return res
      }),
    {
      enabled: isDeviceSignedIn && !isFirebaseLoading && !isSignInLoading,
    }
  )

  const isUserLoading =
    user.isLoading || user.isFetching || isFirebaseLoading || isSignInLoading

  const coin = useQuery('userCoin', () => paymentClient.getUserCoin(), {
    onSuccess: resp => {
      const thirdCoin = resp.find(
        c => c.type === CoinTypeEnum.FREE && c.editable
      )
      const freeCoin = resp.find(
        c => c.type === CoinTypeEnum.FREE && !c.editable
      )
      const paidCoin = resp.find(
        c => c.type === CoinTypeEnum.PAID && !c.editable
      )
      setUserCoin({ freeCoin, paidCoin, thirdCoin })
    },
    enabled:
      !!client?.accessToken &&
      isDeviceSignedIn &&
      !isFirebaseLoading &&
      !isSignInLoading,
  })

  const router = useRouter()

  function randomString(length: number) {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function redirectToLineAuthUrl(mode: 'link' | 'login') {
    const state = randomString(10)
    const redirectUri = `${BASE_URL}/auth/line?mode=${mode}`
    const scope = 'profile%20openid%20email'
    const responseType = 'code'
    window.location.href = `${LINE_AUTH_URL}?response_type=${responseType}&client_id=${LINE_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`
  }

  function redirectToNaiinAuthUrl(mode: 'link' | 'login') {
    const redirectUri = `${BASE_URL}/auth/naiin`
    localStorage.setItem('naiinAuth', mode)
    window.location.href = `${NAIIN_AUTH_URL}?appCode=${NAIIN_APP_CODE}&redirectUrl=${redirectUri}`
  }

  async function signInWithSocialMedia(provider: ProviderDataEnum) {
    setIsSignInLoading(true)
    client?.setSigningIn(true)
    const userCredential: any = await signInWithPopup(auth, providers[provider])
    const rawUserInfo = JSON.parse(userCredential._tokenResponse.rawUserInfo)

    return {
      token: userCredential.user.accessToken,
      birthDay: rawUserInfo.birthday,
      gender: rawUserInfo.gender,
    }
  }

  async function callbackHandleAccountExistFor3rdPartySignIn({
    confirmModal,
    loginModal,
  }: {
    confirmModal: ReturnType<typeof useModal>
    loginModal: ReturnType<typeof useModal>
  }) {
    const { provider, providerId, token, email } = router.query as {
      provider: ProviderDataEnum
      providerId: ProviderDataEnum
      token: string
      email: string
    }

    if (token && provider && providerId && email) {
      try {
        const providerMsg =
          providerId === 'password'
            ? 'อีเมล์และพาสเวิร์ด'
            : providerId.replace('.com', '')

        confirmModal.show({
          content: (
            <div className='grid w-[400px] px-[20px] gap-y-[10px] mb-[20px] mobile:w-[248px] font-mitr text-center text-[24px] text-secondary font-medium'>
              <p>บัญชีนี้เคยเข้าสู่ระบบด้วย {providerMsg} มาก่อน</p>
              <p className='text-[16px] text-secondary-1'>
                หากต้องการเชื่อมบัญชีดังกล่าวกับบัญชีนี้ กรุณากดยืนยัน
                เพื่อเข้าสู่ระบบด้วย {providerMsg} และดำเนินการต่อ
              </p>
            </div>
          ),
          onConfirm: async () => {
            if (providerId === 'password') {
              confirmModal.hide()
              loginModal.show({
                initialValues: { email: email || '' },
              })
              localStorage.setItem(
                'saved3rdPartyCredential',
                JSON.stringify({ provider, token, email })
              )
              return
            }

            // Sign in with the provider and link the account
            const userCredential = await signInWithSocialMedia(providerId)

            if (userCredential) {
              // Sign in the device
              await signInDeviceWithCustomToken(
                userCredential.token,
                providerId
              )

              if (provider === ProviderDataEnum.NAIIN) {
                await linkWithNaiin(token)
              }

              if (provider === ProviderDataEnum.LINE) {
                await linkWithLine(token)
              }
            }

            confirmModal.hide()
          },
          onClose: () => {
            confirmModal.hide()
          },
        })
      } catch (error: any) {
        const errorMsg = error?.response?.errors[0]?.message || error?.message
        alert.error(errorMsg)
      } finally {
        // clear the query params
        router.replace(router.pathname, undefined, { shallow: true })
      }
    }
  }

  async function handleAccountExistFor3rdPartySignIn(
    token: string,
    provider: ProviderDataEnum
  ) {
    if (!token) return
    try {
      let email = ''
      if (provider === ProviderDataEnum.NAIIN) {
        const res = await authenticationClient.getNaiinInformation(token)
        email = res.email
      }

      if (provider === ProviderDataEnum.LINE) {
        const res = await authenticationClient.getLineInformation(token)
        email = res.email
      }

      if (email) {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email)

        if (signInMethods.length > 0) {
          const providerId = signInMethods[0] as ProviderDataEnum
          const _provider = providers[providerId]

          if (!_provider && providerId !== 'password') {
            alert.error(`Provider ${providerId} not supported`)
            return
          }

          return { provider, providerId, token, email }
        }
      }
    } catch (error: any) {
      const errorMsg = error?.response?.errors[0]?.message || error?.message
      alert.error(errorMsg)
      return
    }
  }

  async function linkAccountWithSavedCredential(
    provider: ProviderDataEnum,
    savedCredential: OAuthCredential
  ) {
    try {
      if (!auth.currentUser) return
      const providerMsg = provider.replace('.com', '')
      await linkWithCredential(auth.currentUser, savedCredential)
      alert.success(`เชื่อมต่อ ${providerMsg} account สำเร็จ`)
    } catch (error: any) {
      alert.error(error?.message)
    } finally {
      router.push('/my-profile/setting')
    }
  }

  async function signInWithSocialMediaAndLinkAccount(
    provider: ProviderDataEnum,
    savedCredential: OAuthCredential
  ) {
    try {
      setIsSignInLoading(true)
      client?.setSigningIn(true)
      const userCredential: any = await signInWithPopup(
        auth,
        providers[provider]
      )

      // Link Facebook credential to the existing Google account
      if (userCredential.user) {
        await linkWithCredential(userCredential.user, savedCredential)
        const rawUserInfo = JSON.parse(
          userCredential._tokenResponse.rawUserInfo
        )

        return {
          token: userCredential.user.accessToken,
          birthDay: rawUserInfo.birthday,
          gender: rawUserInfo.gender,
        }
      }

      client?.setSigningIn(false)
      setIsSignInLoading(false)
      return null
    } catch (error: any) {
      alert.error(error?.message)
      client?.setSigningIn(false)
      setIsSignInLoading(false)
      return null
    }
  }

  async function signIn({ email, password }: LoginFormType) {
    setIsSignInLoading(true)
    client?.setSigningIn(true)
    try {
      const userCredential: any = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      client!.accessToken = userCredential.user.accessToken
      signInProviderRef.current = UserFirstLoginProviderEnum.EMAIL
      await signInDevice()
    } catch (error: any) {
      client?.setSigningIn(false)
      alert.error('อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง')
    } finally {
      setIsSignInLoading(false)
    }
  }

  async function customTokenSignIn(customToken: string) {
    setIsSignInLoading(true)
    client?.setSigningIn(true)
    const userCredential: any = await signInWithCustomToken(auth, customToken)
    return {
      token: userCredential.user.accessToken,
      email: userCredential.user.email,
    }
  }

  async function register(form: RegisterFormType) {
    let newUserData

    try {
      newUserData = await userClient.registerWithEmailAndPassword(form)
      await signIn({ email: form.email, password: form.password! })

      if (REGISTER_CONVERSION) {
        event(EVENT_ACTION_ENUM.CONVERSION, {
          send_to: REGISTER_CONVERSION,
        })
      }
      fbqRegisterComplete()

      if (newUserData) {
        await sendWelcomeEmailToNewUser({
          email: newUserData.email,
          name: newUserData.displayName,
        })
      }
    } catch (error: any) {
      if (error?.response?.errors?.length) {
        const errMessage = error?.response?.errors[0]?.message || error?.message
        alert.error(errMessage.replace('Firebase: ', ''))
      }
    }
  }

  async function isUserExist(email: string) {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email)
      return signInMethods.length > 0
    } catch {
      return false
    }
  }

  async function signInWithSocialNetworkService(
    form: SignInWithSocialNetworkServiceFormType
  ) {
    try {
      const res = await userClient.signInWithSocialNetworkService(form)
      signInProviderRef.current = form.firstLoginProvider
      await signInDevice()
      await reloadUser()

      if (REGISTER_CONVERSION) {
        event(EVENT_ACTION_ENUM.CONVERSION, {
          send_to: REGISTER_CONVERSION,
        })
      }
      fbqRegisterComplete()

      if (res.email) {
        const isCurrentUser = await isUserExist(res.email)

        if (!isCurrentUser) {
          await sendWelcomeEmailToNewUser({
            email: res.email,
            name: res.displayName,
          })
        }
      }
    } catch (error: any) {
      if (error?.response?.errors?.length) {
        const errMessage = error?.response?.errors[0]?.message || error?.message
        alert.error(errMessage.replace('Firebase: ', ''))
      }
      await signOut()
    }
  }

  async function forgotPassword(email: string) {
    try {
      const res = await authenticationClient.checkClientIsExist(email)
      if (res) {
        await userClient.forgotPassword(email)
        alert.success(`โปรดตรวจสอบอีเมล ${email} เพื่อรีเซ็ตรหัสผ่าน`)
      } else {
        throw new Error('ไม่พบอีเมลนี้ในระบบ')
      }
    } catch (error: any) {
      alert.error(
        error.response?.errors?.length
          ? 'เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง'
          : error.message
      )
      throw error
    }
  }

  async function resetPassword(resetPasswordToken: string, password: string) {
    try {
      await userClient.resetPassword(resetPasswordToken, password)
    } catch (error: any) {
      const errorMsg = error?.response?.errors.length
        ? error?.response?.errors[0].message
        : 'ไม่สามารถรีเซ็ตพาสเวิร์ดได้ โปรดลองใหม่อีกครั้ง'
      alert.error(errorMsg)
      throw error
    }
  }

  async function signOut() {
    setIsFirebaseLoading(true)
    try {
      queryClient.clear()
      await clearData()
      await auth.signOut()
      await unregisterMessagingSW()
    } catch (error: any) {
      alert.error(error?.message)
    } finally {
      setIsFirebaseLoading(false)
    }
  }

  async function linkWithLine(idToken: string) {
    try {
      const linkResp = await authenticationClient.linkWithLine(idToken)
      if (linkResp?.message === 'success') {
        await forceRefreshToken()
        alert.success('เชื่อมต่อ Line account สำเร็จ')
      }
    } catch (error: any) {
      alert.error(
        `กรุณาเข้าสู่ระบบด้วย Line account แทนการเชื่อมต่อ Line account`
      )
    } finally {
      router.push('/my-profile/setting')
    }
  }

  async function linkWithNaiin(idToken: string) {
    try {
      const linkResp = await authenticationClient.linkWithNaiin(idToken)
      if (linkResp?.message === 'success') {
        await forceRefreshToken()
        alert.success('เชื่อมต่อ Naiin account สำเร็จ')
      }
    } catch (error: any) {
      alert.error(
        `กรุณาเข้าสู่ระบบด้วย Naiin account แทนการเชื่อมต่อ Naiin account`
      )
    } finally {
      router.push('/my-profile/setting')
    }
  }

  async function linkSocialMedia(provider: ProviderDataEnum) {
    const providerMsg = provider.replace('.com', '')

    if (provider === ProviderDataEnum.FACEBOOK) {
      window.FB.login(
        async (loginResponse: any) => {
          try {
            if (loginResponse.authResponse) {
              if (!auth.currentUser) return
              const credential = FacebookAuthProvider.credential(
                loginResponse.authResponse.accessToken
              )
              await linkWithCredential(auth.currentUser, credential)
              alert.success(`เชื่อมต่อ ${providerMsg} account สำเร็จ`)
            }
          } catch (error: any) {
            alert.error(
              `กรุณาเข้าสู่ระบบด้วย ${providerMsg} account แทนการเชื่อมต่อ ${providerMsg} account`
            )
          }
        },
        { scope: 'email' }
      )
    } else {
      try {
        if (!auth.currentUser) return
        if (provider === ProviderDataEnum.LINE) {
          redirectToLineAuthUrl('link')
        } else if (provider === ProviderDataEnum.NAIIN) {
          redirectToNaiinAuthUrl('link')
        } else {
          await linkWithPopup(auth.currentUser, providers[provider])
          alert.success(`เชื่อมต่อ ${providerMsg} account สำเร็จ`)
        }
      } catch (e: any) {
        alert.error(
          `กรุณาเข้าสู่ระบบด้วย ${providerMsg} account แทนการเชื่อมต่อ ${providerMsg} account`
        )
      }
    }
  }

  async function unlinkSocialMedia(provider: ProviderDataEnum) {
    const providerMsg = provider.replace('.com', '')
    try {
      if (!auth.currentUser) return
      if (provider === ProviderDataEnum.LINE) {
        await authenticationClient.unlinkLineProvider()
        await forceRefreshToken()
      } else if (provider === ProviderDataEnum.NAIIN) {
        await authenticationClient.unlinkNaiinProvider()
        await forceRefreshToken()
      } else {
        await unlink(auth.currentUser, provider)
        setProviderData(prevData =>
          prevData.filter(value => value !== provider)
        )
      }
      alert.success(`ยกเลิกเชื่อมต่อ ${providerMsg} account สำเร็จ`)
    } catch (_) {
      alert.error(`ยกเลิกเชื่อมต่อ ${providerMsg} account ไม่สำเร็จ`)
    }
  }

  const {
    mutateAsync: handleLinkSocialMedia,
    isLoading: isLinkSocialMediaLoading,
  } = useMutation(
    ({ value, provider }: { value: boolean; provider: ProviderDataEnum }) =>
      value ? linkSocialMedia(provider) : unlinkSocialMedia(provider)
  )

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      if (auth.currentUser) {
        const credential = EmailAuthProvider.credential(
          user.data?.email || '',
          oldPassword
        )
        await reauthenticateWithCredential(auth.currentUser, credential)
        alert.success('เปลี่ยนรหัสผ่านสำเร็จ')
        await updatePassword(auth.currentUser, newPassword)
      }
    } catch (error: any) {
      if (error.message?.includes('auth/wrong-password')) {
        alert.error('รหัสผ่านเดิมไม่ถูกต้อง')
      } else {
        alert.error('เปลี่ยนรหัสผ่านไม่สำเร็จ')
      }
    }
  }

  async function sendWelcomeEmailToNewUser({
    email,
    name,
  }: {
    email: string
    name: string
  }) {
    const { getFirestore, collection } = await import('@firebase/firestore')
    const { addDoc } = await import('@firebase/firestore')
    const db = getFirestore()
    const mailCollection = collection(db, 'mail')
    await addDoc(mailCollection, {
      to: email,
      template: {
        name: 'welcome',
        data: {
          name,
        },
      },
    })
  }

  async function signInDeviceWithCustomToken(
    token: string,
    provider: ProviderDataEnum
  ) {
    const fcmToken = await requestFCMToken()
    client!.accessToken = token
    const res = await deviceClient.signInDevice({
      provider: providerDataValue[provider],
      deviceToken: fcmToken,
    })
    setDevices(res.devices)
    setDeviceSignedIn(res.canSignIn)
    setShowLimitAuthentication(
      res.devices.length === 5 && res.canSignIn === false
    )
    setIsSignInLoading(false)
  }

  async function signInDevice() {
    try {
      const result: any = await auth.currentUser?.getIdTokenResult()

      if (result?.claims?.admin) {
        setDevices([])
        setDeviceSignedIn(true)
        return
      }

      const fcmToken = await requestFCMToken()
      const res = await deviceClient.signInDevice({
        provider: signInProviderRef.current,
        deviceToken: fcmToken,
      })

      if (res) {
        setDevices(res.devices)
        setDeviceSignedIn(res.canSignIn)
        setShowLimitAuthentication(
          res.devices.length === 5 && res.canSignIn === false
        )
      }
    } catch (_) {
      // Don't need to do anything
    } finally {
      setIsSignInLoading(false)
    }
  }

  async function forceRefreshToken() {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true)
    }
  }

  async function deleteFirebaseAccount() {
    try {
      if (!user.data) {
        await auth.currentUser?.delete()
      }
    } catch (_) {
      // Don't need to do anything
    }
  }

  async function clearData() {
    await client?.setupHeader(undefined)
    setDeviceSignedIn(false)
    setToken(undefined)
    setDevices([])
    setProviderData([])
    setShowLimitAuthentication(false)
    userRef.current = undefined
  }

  // listen for token changes
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (firebaseUser: any) => {
      if (!userRef.current) {
        setIsFirebaseLoading(true)
      }

      if (firebaseUser) {
        const tokenId = await auth.currentUser?.getIdToken()
        if (isDeviceSignedIn) {
          await client?.setupHeader(tokenId)
        }
        let providerIds =
          auth?.currentUser?.providerData?.map(row => row.providerId) ?? []
        const result: any = await auth.currentUser?.getIdTokenResult()
        // DESC: Find a custom providers and asign to providerIds
        if (result?.claims?.providers) {
          providerIds = providerIds.concat(
            result.claims.providers.map((row: any) => row.providerId) ?? []
          )
        }
        setProviderData(providerIds as ProviderDataEnum[])
        setToken(tokenId)
      } else {
        await clearData()
      }
      setIsFirebaseLoading(false)
    })

    return unsubscribe
  }, [])

  async function requestFCMToken(): Promise<string | undefined> {
    try {
      const { getMessaging, getToken, isSupported } = await import(
        '@firebase/messaging'
      )

      const isSupportedBrowser = await isSupported()
      if (!isSupportedBrowser || Notification?.permission !== 'granted') {
        return undefined
      }

      const messaging = getMessaging(firebase)
      const messagingSW = await registerMessagingSW()
      if (messagingSW) {
        const currentToken = await getToken(messaging, {
          vapidKey: FCM_KEY,
          serviceWorkerRegistration: messagingSW,
        })

        return currentToken
      }

      return undefined
    } catch (_) {
      return undefined
    }
  }

  function requestNotificationPermission() {
    if (
      'Notification' in window &&
      Notification.permission !== 'denied' &&
      Notification.permission !== 'granted'
    ) {
      Notification.requestPermission().then(async permission => {
        try {
          if (permission === 'granted' && client?.accessToken) {
            const fcmToken = await requestFCMToken()
            if (fcmToken) {
              await deviceClient.setDeviceToken(fcmToken)
            }
          }
        } catch {
          // Don't need to do anything
        }
      })
    }
  }

  async function refetchMyProfile() {
    await user.refetch()
  }

  async function refetchUserCoin() {
    await coin.refetch()
  }

  async function updateFirebaseEmail(email: string) {
    if (auth.currentUser) {
      await updateEmail(auth.currentUser, email)
    }
  }

  async function reloadUser() {
    if (auth.currentUser) {
      await auth.currentUser.reload()
    }
  }

  function setDeviceSignedIn(value: boolean) {
    setIsDeviceSignedIn(value)
    if (value) {
      localStorage.setItem('isDeviceSignedIn', 'true')
    } else {
      localStorage.removeItem('isDeviceSignedIn')
    }
  }

  useEffect(() => {
    requestNotificationPermission()
  }, [])

  const value = {
    user: user.data || defaultUser,
    userCoin: userCoin || defaultUserCoin,
    providerData,
    isUserLoading,
    isAuthenticated: !!user.data && isDeviceSignedIn,
    isLogin: !!client?.isSigningIn,
    isWriter: !!user.data?.writerRequest?.writer?.id,
    isLinkSocialMediaLoading,
    token,
    devices,
    canFetchApi:
      (!isUserLoading && isDeviceSignedIn && !!token) ||
      (!isUserLoading && !isDeviceSignedIn && !token),
    showLimitAuthentication,
    signIn,
    signOut,
    signInDevice,
    signInWithSocialMedia,
    signInWithSocialMediaAndLinkAccount,
    register,
    forgotPassword,
    resetPassword,
    changePassword,
    providers,
    handleLinkSocialMedia,
    deleteFirebaseAccount,
    setDevices,
    setIsSignInLoading,
    customTokenSignIn,
    linkWithLine,
    linkWithNaiin,
    requestFCMToken,
    forceRefreshToken,
    refetchMyProfile,
    redirectToLineAuthUrl,
    redirectToNaiinAuthUrl,
    signInWithSocialNetworkService,
    updateFirebaseEmail,
    reloadUser,
    setDeviceSignedIn,
    setShowLimitAuthentication,
    refetchUserCoin,
    linkAccountWithSavedCredential,
    handleAccountExistFor3rdPartySignIn,
    signInDeviceWithCustomToken,
    callbackHandleAccountExistFor3rdPartySignIn,
  }

  return (
    <AuthenticateContext.Provider value={value}>
      {children}
    </AuthenticateContext.Provider>
  )
}

function useAuthentication() {
  return useContext(AuthenticateContext)
}

export { auth, useAuthentication }
