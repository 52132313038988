export enum PromotionTypeEnum {
  BUY_ALL = 'BUY_ALL',
  READ_FREE = 'READ_FREE',
}

export enum PromotionBuyAllEnum {
  COIN = 'COIN',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PromotionBuyAllValueType {
  COIN = 'เหรียญ',
  PERCENTAGE = '%',
}

export enum PromotionReadFreeEnum {
  READ_FREE_PER_DAY = 'READ_FREE_PER_DAY',
  READ_FREE_WITHIN_DAY = 'READ_FREE_WITHIN_DAY',
  READ_FREE_INTERVAL = 'READ_FREE_INTERVAL',
}

export enum PromotionAlertTypeEnum {
  MANAGE_PROMOTION = 'MANAGE_PROMOTION',
  NEW_CHAPTER = 'NEW_CHAPTER',
  SET_COIN = 'SET_COIN',
  UPDATE_STORY_STATUS = 'UPDATE_STORY_STATUS',
  UPDATE_EBOOK_STATUS = 'UPDATE_EBOOK_STATUS',
  REMOVE_CHAPTER = 'REMOVE_CHAPTER',
}
