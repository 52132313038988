import cn from 'classnames'
import { TwitterShareButton } from 'react-share'

import { LinkShareButton } from '@components/LinkShareButton'
import { TwitterIcon } from '@components/icons/TwitterIcon'
import { LineIcon } from '@components/icons/LineIcon'
import { LineShareButton } from '@components/socials/LineShareButton'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { PromotionChallengeSocialEnum } from '@interfaces/PromotionChallengeSocialEnum'
import { FacebookButton } from '@components/socials/FacebookButton'
import { SHARE_FACEBOOK_CONVERSION } from '@configs/config'
import { event } from '@lib/gtag'
import { EVENT_ACTION_ENUM } from '@configs/config'
import { ShareButtonsProps } from './interface'

export const shareButtonStyle: any = {
  character: {
    width: '30',
    height: '30',
    linkIconWidth: '18',
    linkIconHeight: '18',
    shareIconWith: '20',
    shareIconHeight: '20',
  },
  story: { width: '40', height: '40' },
  story2: { width: '40', height: '40' },
  chapter: { width: '40', height: '40' },
}

export const ShareButtons = ({
  bookId,
  bookChapterId,
  shareUrl,
  type = 'character',
  className = '',
  createUserShare,
}: ShareButtonsProps) => {
  const { isDarkTheme, isLightTheme, isNightTheme, isSepiaTheme } = useTheme()

  return (
    // <div
    //   className={cn(
    //     'grid grid-cols-4 gap-x-[10px] mt-[5px] p-[20px] border rounded-[8px] shadow overflow-hidden',
    //     className,
    //     {
    //       'bg-white border-gray-4': isLightTheme,
    //       'bg-sepia border-sepia-2': isSepiaTheme,
    //       'bg-dark border-dark-3': isNightTheme,
    //       'bg-dark-5 border-dark-3': isDarkTheme,
    //     }
    //   )}
    // >
    <div
      className={cn(
        'grid grid-cols-3 gap-x-[10px] mt-[5px] p-[20px] border rounded-[8px] shadow overflow-hidden',
        className,
        {
          'bg-white border-gray-4': isLightTheme,
          'bg-sepia border-sepia-2': isSepiaTheme,
          'bg-dark border-dark-3': isNightTheme,
          'bg-dark-5 border-dark-3': isDarkTheme,
        }
      )}
    >
      <FacebookButton
        className={cn('hidden',{ 'opacity-50': isDarkTheme })}
        url={shareUrl}
        width={shareButtonStyle[type].width}
        height={shareButtonStyle[type].height}
        onSuccess={() => {
          if (SHARE_FACEBOOK_CONVERSION) {
            event(EVENT_ACTION_ENUM.CONVERSION, {
              send_to: SHARE_FACEBOOK_CONVERSION,
            })
          }
          if (bookId) {
            createUserShare({
              bookId,
              bookChapterId,
              platform: PromotionChallengeSocialEnum.FACEBOOK,
            })
          }
        }}
        onError={() => {}}
      />
      {/* <FacebookShareButton
        url={encodeURI(shareUrl)}
        onClick={() => {
          if (bookId)
            createUserShare({
              bookId,
              bookChapterId,
              platform: PromotionChallengeSocialEnum.FACEBOOK,
            })
        }}
      >
        <FacebookIcon
          className={cn({ 'opacity-50': isDarkTheme })}
          width={shareButtonStyle[type].width}
          height={shareButtonStyle[type].height}
        />
      </FacebookShareButton> */}
      <LineShareButton
        url={encodeURI(shareUrl)}
        onClick={() => {
          if (bookId)
            createUserShare({
              bookId,
              bookChapterId,
              platform: PromotionChallengeSocialEnum.LINE,
            })
        }}
      >
        <LineIcon
          className={cn({ 'opacity-50': isDarkTheme })}
          width={shareButtonStyle[type].width}
          height={shareButtonStyle[type].height}
        />
      </LineShareButton>
      <div>
        <TwitterShareButton
          url={encodeURI(shareUrl)}
          onClick={() => {
            if (bookId)
              createUserShare({
                bookId,
                bookChapterId,
                platform: PromotionChallengeSocialEnum.TWITTER,
              })
          }}
        >
          <TwitterIcon
            className={cn({ 'opacity-50': isDarkTheme })}
            width={shareButtonStyle[type].width}
            height={shareButtonStyle[type].height}
          />
        </TwitterShareButton>
      </div>
      <LinkShareButton
        className={cn({
          '!w-[30px] !h-[30px]': type === 'character',
          'opacity-50': isDarkTheme,
        })}
        url={encodeURI(shareUrl)}
        color={type === 'story2' ? 'white' : 'gray'}
        iconWidth={shareButtonStyle[type].linkIconWidth}
        iconHeight={shareButtonStyle[type].linkIconHeight}
      />
    </div>
  )
}
