import { Expose, Type, Transform } from 'class-transformer'

import { BookReadFreePromotionType } from './BookReadFreePromotionType'

class AlreadyReadChapterType {
  @Expose() chapterId: number

  @Expose() createdAt: string

  @Expose() expiredAt: string
}

export class BookReadFreePromotionDetailType {
  @Expose()
  @Type(() => AlreadyReadChapterType)
  @Transform(({ value }) => value || [])
  alreadyReadChapter: AlreadyReadChapterType[]

  @Expose()
  @Type(() => BookReadFreePromotionType)
  currentReadPromotion: BookReadFreePromotionType

  @Expose() todayReadCount: number
}
