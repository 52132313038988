import { gql } from 'graphql-request'

export const UPDATE_BOOK_READ_PROMOTION = gql`
  mutation Mutation(
    $updateBookReadPromotionInput: UpdateBookReadPromotionInput!
  ) {
    updateBookReadPromotion(
      updateBookReadPromotionInput: $updateBookReadPromotionInput
    ) {
      id
    }
  }
`
