import { Expose, Type } from 'class-transformer'

import { TableContentReaderType } from './TableContentReaderType'

export class TableContentReaderResponse {
  @Expose({ name: 'data' })
  @Type(() => TableContentReaderType)
  chapters: TableContentReaderType[]

  @Expose() page: number
}
