import { Expose } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'
import { ChapterTypeEnum } from '@interfaces/ChapterTypeEnum'

export class BookType {
  @Expose() id: number

  @Expose() bookType: BookEnum

  @Expose() title: string

  @Expose() intro: string

  @Expose() chapterType: ChapterTypeEnum

  @Expose()
  chapterCount: number
}
