import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { CHECK_COUPON_CODE } from '@client/collections/Payment/schemas/checkCouponCode'
import { CHECK_TRANSACTION_STATUS } from '@client/collections/Payment/schemas/checkTransactionStatus'
import { CREATE_PAYMENT_CARD } from '@client/collections/Payment/schemas/createPaymentCard'
import { CREATE_PAYMENT_QR } from '@client/collections/Payment/schemas/createPaymentQR'
import { CREATE_PAYMENT_QR_DOLFIN } from '@client/collections/Payment/schemas/createPaymentQRDolfin'
import { CREATE_PAYMENT_SHOPEE_PAY } from '@client/collections/Payment/schemas/createPaymentShopeePay'
import { GET_COIN_LOG_LIST } from '@client/collections/Payment/schemas/getCoinLogList'
import { REMOVE_PAYMENT_QR } from '@client/collections/Payment/schemas/removePaymentQR'
import { TRANSACTION } from '@client/collections/Payment/schemas/transaction'
import { CoinLogEnum } from '@interfaces/CoinLogEnum'
import { PaymentMethodTypeEnum } from '@interfaces/PaymentMethodTypeEnum'
import { PlatformEnum } from '@interfaces/PlatformEnum'
import { SortEnum } from '@interfaces/SortEnum'
import { StatusEnum } from '@interfaces/StatusEnum'
import { UserCoinLogsResponse } from '@models/myCoin/UserCoinLogsResponse'
import { CheckPromotionCodeType } from '@models/payment/CheckPromotionCodeType'
import { PaymentQRDolfinType } from '@models/payment/PaymentQRDolfinType'
import { PaymentShopeePayType } from '@models/payment/PaymentShopeePayType'
import { TransactionType } from '@models/transaction/TransactionType'
import { gqlApiInstance } from '@client/init'
import { GET_USER_COIN_RECORDS_FREE_COINS } from '@client/collections/Payment/schemas/getUserCoinRecordsFreeCoins'
import { UserFreeCoinResponse } from '@models/myCoin/UserFreeCoinResponse'
import { COINS } from '@client/collections/Payment/schemas/coins'
import { CoinStatusEnum } from '@interfaces/CoinStatusEnum'
import { CoinType } from '@models/payment/CoinType'
import { CoinTypeEnum } from '@interfaces/CoinTypeEnum'
import { CoinNearExpirationType } from '@models/myCoin/CoinNearExpirationType'
import { GET_USER_COIN_RECORDS_NEAR_EXPIRATION } from '@client/collections/Payment/schemas/getUserCoinRecordsNearExpiration'
import { GET_USER_COIN } from '@client/collections/Payment/schemas/getUserCoin'
import { UserCoinType } from '@models/payment/UserCoinType'
import { CheckTransactionStatusEnum } from '@interfaces/CheckTransactionStatusEnum'
import { event } from '@lib/gtag'
import { ADD_COIN_CONVERSION, EVENT_ACTION_ENUM } from '@configs/config'
import { CREATE_INTERNET_BANKING_TRANSACTION } from '@client/collections/Payment/schemas/createInternetBankingTransaction'
import { CreateInternetBankingType } from '@models/payment/CreateInternetBankingType'
import { CREATE_OR_UPDATE_PAYMENT_TRUE_MONEY } from '@client/collections/Payment/schemas/createOrUpdatePaymentTrueMoney'
import { CreateOrUpdatePaymentTrueMoneyType } from '@models/payment/CreateOrUpdatePaymentTrueMoneyType'
import { CONFIRM_PAYMENT_TRUE_MONEY } from '@client/collections/Payment/schemas/confirmPaymentTrueMoney'
import { ConfirmPaymentTrueMoneyType } from '@models/payment/ConfirmPaymentTrueMoney'
import { CANCEL_PAYMENT_TRUE_MONEY } from '@client/collections/Payment/schemas/cancelPaymentTrueMoney'
import { CoinsLogFilterEnum } from '@interfaces/CoinsLogFilterEnum'

export function usePaymentAction() {
  async function createPaymentCard({
    token,
    amount,
    platform,
    saveCard,
    coupon,
    coinValue,
  }: {
    token: string
    amount: number
    platform: PlatformEnum
    saveCard: boolean
    coupon?: string
    coinValue?: number
  }): Promise<{ redirectUrl: string; status: StatusEnum }> {
    const res = await gqlApiInstance.request(CREATE_PAYMENT_CARD, {
      createPaymentCardInput: {
        token,
        amount,
        platform,
        saveCard,
        coupon,
        coinValue,
      },
    })
    return res.createPaymentCard
  }

  async function createPaymentQR({
    amount,
    platform,
    coupon,
    coinValue,
  }: {
    amount: number
    platform: PlatformEnum
    coupon?: string
    coinValue?: number
  }): Promise<{ orderId: string }> {
    const res = await gqlApiInstance.request(CREATE_PAYMENT_QR, {
      createPaymentQrInput: { amount, platform, coupon, coinValue },
    })
    return res.createPaymentQR
  }

  // TODO: ลบด้วยจ้า
  async function removePaymentQR(orderId: string) {
    const res = await gqlApiInstance.request(REMOVE_PAYMENT_QR, {
      orderId,
    })
    return res.removePaymentQR
  }

  async function getCoinLogList({
    perpage,
    page,
    sortKey,
    sortValue,
    type,
    startDate,
    endDate,
    filter,
  }: {
    perpage: number
    page: number
    sortKey: string
    sortValue: SortEnum
    type?: CoinLogEnum
    startDate?: Date
    endDate?: Date
    filter?: CoinsLogFilterEnum
  }): Promise<UserCoinLogsResponse> {
    const res = await gqlApiInstance.request(GET_COIN_LOG_LIST, {
      limitPerPage: perpage,
      page,
      orderBy: { [sortKey]: sortValue },
      type,
      startDate: startDate ? DateTime.fromJSDate(startDate).toUTC() : undefined,
      endDate: endDate
        ? DateTime.fromJSDate(endDate).endOf('day').toUTC()
        : undefined,
      filter,
    })

    return plainToInstance(UserCoinLogsResponse, res.userCoinLogs)
  }

  async function getUserCoinRecordsFreeCoins({
    perpage,
    page,
    sortKey,
    sortValue,
    coinId,
    startDate,
    endDate,
  }: {
    perpage: number
    page: number
    sortKey: string
    sortValue: SortEnum
    coinId?: number
    startDate?: Date
    endDate?: Date
  }): Promise<UserFreeCoinResponse> {
    const res = await gqlApiInstance.request(GET_USER_COIN_RECORDS_FREE_COINS, {
      limitPerPage: perpage,
      page,
      coinId,
      orderBy: { [sortKey]: sortValue },
      startDate: startDate ? DateTime.fromJSDate(startDate).toUTC() : undefined,
      endDate: endDate
        ? DateTime.fromJSDate(endDate).endOf('day').toUTC()
        : undefined,
    })

    return plainToInstance(
      UserFreeCoinResponse,
      res.getUserCoinRecordsFreeCoins
    )
  }

  async function getActiveFreeCoins(): Promise<CoinType[]> {
    const { coins } = await gqlApiInstance.request(COINS, {
      where: {
        status: CoinStatusEnum.ACTIVE,
        visible: true,
      },
      type: CoinTypeEnum.FREE,
      orderBy: {
        id: SortEnum.ASC,
      },
    })
    return plainToInstance(CoinType, coins.data as [])
  }

  async function getUserCoinRecordsNearExpiration(): Promise<
    CoinNearExpirationType[]
  > {
    const res = await gqlApiInstance.request(
      GET_USER_COIN_RECORDS_NEAR_EXPIRATION
    )
    return plainToInstance(
      CoinNearExpirationType,
      res.getUserCoinRecordsNearExpiration as []
    )
  }

  async function transaction({
    transactionId,
    chargeId,
  }: {
    transactionId?: string
    chargeId?: string
  }): Promise<TransactionType> {
    const res = await gqlApiInstance.request(TRANSACTION, {
      transactionId,
      chargeId,
    })
    return res.transaction
  }

  async function createPaymentQRDolfin({
    amount,
    platform,
    coupon,
    coinValue,
  }: {
    amount: number
    platform: PlatformEnum
    coupon?: string
    coinValue?: number
  }): Promise<PaymentQRDolfinType> {
    const res = await gqlApiInstance.request(CREATE_PAYMENT_QR_DOLFIN, {
      createPaymentDolfinInput: { amount, platform, coupon, coinValue },
    })
    return res.createPaymentQRDolfin
  }

  async function createPaymentShopeePay({
    amount,
    platform,
    returnUrl,
    coupon,
    coinValue,
  }: {
    amount: number
    platform: PlatformEnum
    returnUrl: string
    coupon?: string
    coinValue?: number
  }): Promise<PaymentShopeePayType> {
    const res = await gqlApiInstance.request(CREATE_PAYMENT_SHOPEE_PAY, {
      createShopeePayInput: {
        amount,
        platform,
        returnUrl,
        coupon,
        coinValue,
      },
    })
    return res.createPaymentShopeePay
  }

  async function checkTransactionStatus({
    referenceId,
    amount,
  }: {
    referenceId: string
    amount: number
  }): Promise<CheckTransactionStatusEnum> {
    const res = await gqlApiInstance.request(CHECK_TRANSACTION_STATUS, {
      checkTransactionStatus: { referenceId, amount },
    })
    return res.checkTransactionStatus
  }

  async function checkCouponCode({
    coupon,
    paymentMethod,
  }: {
    coupon: string
    paymentMethod: PaymentMethodTypeEnum
  }): Promise<CheckPromotionCodeType> {
    const {
      checkCouponCode: { promotionCode },
    } = await gqlApiInstance.request(CHECK_COUPON_CODE, {
      coupon,
      paymentMethod,
    })

    return plainToInstance(CheckPromotionCodeType, promotionCode)
  }

  async function getUserCoin(): Promise<UserCoinType[]> {
    const res = await gqlApiInstance.request(GET_USER_COIN)
    return plainToInstance(UserCoinType, res.getUserCoin as [])
  }

  function sendAddCoinConversion(transactionId: string, value: number) {
    if (ADD_COIN_CONVERSION) {
      event(EVENT_ACTION_ENUM.CONVERSION, {
        send_to: ADD_COIN_CONVERSION,
        value,
        currency: 'THB',
        transaction_id: transactionId,
      })
    }
  }

  async function createInternetBankingTransaction({
    amount,
    coupon,
    coinValue,
  }: {
    amount: number
    coupon?: string
    coinValue?: number
  }): Promise<CreateInternetBankingType> {
    const res = await gqlApiInstance.request(
      CREATE_INTERNET_BANKING_TRANSACTION,
      {
        createInternetBankingInput: {
          amount,
          coupon,
          coinValue,
        },
      }
    )
    return res.createInternetBankingTransaction
  }

  async function createOrUpdatePaymentTrueMoney({
    account,
    amount,
    platform,
    coupon,
    transactionId,
  }: {
    account: string
    amount: number
    platform: PlatformEnum
    coupon?: string
    transactionId?: string
  }): Promise<CreateOrUpdatePaymentTrueMoneyType> {
    const res = await gqlApiInstance.request(
      CREATE_OR_UPDATE_PAYMENT_TRUE_MONEY,
      {
        createOrUpdatePaymentTrueMoneyInput: {
          account,
          amount,
          platform,
          coupon,
          transactionId,
        },
      }
    )
    return plainToInstance(
      CreateOrUpdatePaymentTrueMoneyType,
      res.createOrUpdatePaymentTrueMoney
    )
  }

  async function confirmPaymentTrueMoney({
    account,
    authorizationCode,
    otpCode,
    otpReference,
    transactionId,
  }: {
    account: string
    authorizationCode: string
    otpCode: string
    otpReference: string
    transactionId: string
  }): Promise<ConfirmPaymentTrueMoneyType> {
    const res = await gqlApiInstance.request(CONFIRM_PAYMENT_TRUE_MONEY, {
      confirmPaymentTrueMoneyInput: {
        account,
        authorizationCode,
        otpCode,
        otpReference,
        transactionId,
      },
    })
    return plainToInstance(
      ConfirmPaymentTrueMoneyType,
      res.confirmPaymentTrueMoney
    )
  }

  async function cancelPaymentTrueMoney({
    transactionId,
  }: {
    transactionId: string
  }): Promise<{ message: string }> {
    const res = await gqlApiInstance.request(CANCEL_PAYMENT_TRUE_MONEY, {
      transactionId,
    })
    return res.cancelPaymentTrueMoney
  }

  return {
    createPaymentCard,
    createPaymentQR,
    removePaymentQR,
    getCoinLogList,
    transaction,
    createPaymentQRDolfin,
    createPaymentShopeePay,
    checkTransactionStatus,
    checkCouponCode,
    getUserCoinRecordsFreeCoins,
    getActiveFreeCoins,
    getUserCoinRecordsNearExpiration,
    getUserCoin,
    sendAddCoinConversion,
    createInternetBankingTransaction,
    createOrUpdatePaymentTrueMoney,
    confirmPaymentTrueMoney,
    cancelPaymentTrueMoney,
  }
}
