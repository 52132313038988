import { gql } from 'graphql-request'

export const BOOK_CHAPTER_WITH_LOG = gql`
  query BookChapter($bookChapterId: Int!) {
    bookChapter(id: $bookChapterId) {
      id
      chapterTitle
      chapterName
      book {
        title
        bookType
      }
      bookChapterLogs {
        id
        updatedAt
        writerMessage
        chapterPageLogs {
          id
          content
          imgPath
        }
      }
    }
  }
`
