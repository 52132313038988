import { gql } from 'graphql-request'

export const ACTIVE_PROMOTION_SALES = gql`
  query ActivePromotionSales($bookId: Int!) {
    activePromotionSales(bookId: $bookId) {
      name
      startPublishedAt
      endPublishedAt
    }
  }
`
