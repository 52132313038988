import React, { useEffect, useState } from 'react'

import { useAuthentication } from '@hooks/useAuthentication'
import { useRemoveUserReading } from '@hooks/userReading/useRemoveUserReading'
import { useAlert } from '@hooks/useAlert'
import { useUserReadingAction } from '@hooks/userReading/useUserReadingAction'
import { UserReadingType } from '@models/userReading/UserReadingType'
import { ContinueToReadProps } from './interface'

export default function withContinueToRead(
  Component: React.FC<ContinueToReadProps>
) {
  function WithContinueToRead() {
    const { isAuthenticated, canFetchApi } = useAuthentication()
    const alert = useAlert()
    const userReadingClient = useUserReadingAction()
    const [userReadings, setUserReading] = useState<UserReadingType[]>([])

    const { mutateAsync: removeUserReading } = useRemoveUserReading(
      async () => {
        const memberBooks = await getUserReadings()
        setUserReading(memberBooks)
        alert.success('ลบรายการที่อ่านอยู่สำเร็จ')
      }
    )

    async function getUserReadings() {
      const res = await userReadingClient.userReadings({
        page: 1,
        limit: 3,
        sort: 'updatedAt:desc',
      })

      return res.data.map(row => ({
        id: row.id,
        bookId: row.book.id,
        chapterId: row.chapter.id,
        writerUserId: row.book.penName.user.id,
        bookTitle: row.book.title,
        chapterName: row.chapter.chapterName,
        coverImgPath: row.book.coverResizeImgPath || row.book.coverImgPath,
        bookWriter: row.book.penName.firstPenName,
        updatedAt: row.updatedAt,
        profilePageSlug: row.book.penName.user.profilePageSlug,
      }))
    }

    async function handleDeleteUserReading(id: number) {
      const userReadingLocal = await userReadingClient.getGuestReading(true)
      if (isAuthenticated) {
        await removeUserReading(id)
      } else if (userReadingLocal && !isAuthenticated) {
        const guestBooks = userReadings.filter(row => row.id !== id)
        userReadingClient.replaceGuestReading(guestBooks)
        alert.success('ลบรายการที่อ่านอยู่สำเร็จ')
        setUserReading(guestBooks)
      }
    }

    async function initialUserReading() {
      if (isAuthenticated) {
        const memberBooks = await getUserReadings()
        setUserReading(memberBooks)
        return
      }

      const userReadingLocal = await userReadingClient.getGuestReading()
      if (userReadingLocal.length) {
        setUserReading(userReadingLocal)
        return
      }

      setUserReading([])
    }

    useEffect(() => {
      if (canFetchApi) initialUserReading()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canFetchApi])

    if (userReadings.length) {
      return (
        <Component
          userReadings={userReadings}
          isAuthenticated={isAuthenticated}
          handleDeleteUserReading={handleDeleteUserReading}
        />
      )
    }

    return <></>
  }

  return WithContinueToRead
}
