import { Expose, Type } from 'class-transformer'
import { BookEnum } from '@interfaces/BookEnum'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { BookCategoryType } from '@models/book/BookCategoryType'
import { BookTagsType } from '@models/book/BookTagsType'

class BookType {
  @Expose() id: number

  @Expose() title: string

  @Expose() bookType: BookEnum

  @Expose() status: BookStatusEnum

  @Expose() coverImgPath: string

  @Expose() cover: string

  @Expose() facebookShareImageUrl: string

  @Expose() twitterShareImageUrl: string

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose()
  @Type(() => BookTagsType)
  tags: BookTagsType[]

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType
}

export class PenNameType {
  id: number

  firstPenName: string
}

export class BookChapterMetaDataType {
  @Expose() id: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose()
  @Type(() => BookType)
  book: BookType

  @Expose() chapterCoverImgPath: string
}
