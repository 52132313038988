import { gql } from 'graphql-request'

export const UPDATE_BOOK_BUY_ALL_PROMOTION = gql`
  mutation Mutation(
    $updateBookBuyAllChaptersPromotionInput: UpdateBookBuyAllChaptersPromotionInput!
  ) {
    updateBookBuyAllChaptersPromotion(
      updateBookBuyAllChaptersPromotionInput: $updateBookBuyAllChaptersPromotionInput
    ) {
      id
    }
  }
`
