import { Exclude, Expose, Transform, Type } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'
import { OrientationEnum } from '@interfaces/OrientationEnum'
import { PenNameType } from '@models/penName/PenNameType'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { BookCategoryType } from '@models/book/BookCategoryType'

@Exclude()
class RatingType {
  @Expose()
  id: number
}

@Exclude()
export class BookPreviewType {
  @Expose() id: number

  @Expose() title: string

  @Expose() guestCommentable: boolean

  @Expose() screenCapturable: boolean

  @Expose()
  @Type(() => PenNameType)
  penName: PenNameType

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  orientation: OrientationEnum

  @Expose()
  @Transform(({ value }) => value.toUpperCase())
  bookType: BookEnum

  @Expose()
  status: BookStatusEnum

  @Expose()
  @Type(() => BookCategoryType)
  category: BookCategoryType

  @Expose() coverResizeImgPath: string

  @Expose() coverImgPath: string

  @Expose() requireAgeVerify: boolean

  @Expose()
  @Type(() => RatingType)
  rating: RatingType
}
