import { gql } from 'graphql-request'

export const READ_NOW = gql`
  query ReadNow($bookId: Int!, $bookChapterId: Int) {
    readNow(bookId: $bookId, bookChapterId: $bookChapterId) {
      readingPercentage
      chapterPageId
      chapterId
      id
    }
  }
`
