import { gql } from 'graphql-request'

export const BOOK_CHAPTER = gql`
  query BookChapter($id: Int!) {
    bookChapter(id: $id) {
      id
      chapterName
      chapterTitle
      chapterCoverImgPath
      chapterPages {
        id
        imgPath
        content
        ordered
        size
      }
      book {
        title
        writer
        bookType
      }
      status
      editedStatus
      commentable
      writerMessage
      isNoticeReader
      publishedAt
    }
  }
`
export const GET_BOOK_FOR_CREATE = gql`
  query GetBookForCreate($id: Int!) {
    getBookForCreate(id: $id) {
      id
      bookType
      title
      intro
      chapterType
      chapterCount
    }
  }
`

export const BOOK_CHAPTER_PREVIEW = gql`
  query BookChapterPreview($id: Int!) {
    bookChapterWriter(id: $id) {
      id
      chapterName
      chapterTitle
      writerMessage
      prevId
      nextId
      chapterPages {
        id
        imgPath
        content
        ordered
      }
      book {
        id
        title
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            displayName
            avatarImgPath
            profilePageSlug
            writer {
              affiliation
              status
            }
            isAdmin
            email
          }
        }
        orientation
        bookType
      }
    }
  }
`

export const BOOK_CHAPTER_READER = gql`
  query BookChapterReader($id: Int!) {
    bookChapter(id: $id) {
      id
      chapterName
      chapterTitle
      writerMessage
      prevId
      nextId
      commentCount
      lastCommentDate
      commentable
      isPaid
      price
      status
      isUserHasBlockedComment
      chapterCoverImgPath
      passReadPromotion
      publishedAt
      chapterPages {
        id
        imgPath
        content
        ordered
      }
      book {
        id
        title
        guestCommentable
        penName {
          id
          firstPenName
          user {
            id
            displayName
            avatarImgPath
            profilePageSlug
            writer {
              affiliation
              status
            }
            isAdmin
            email
            isPermanentBan
          }
        }
        rating {
          id
        }
        orientation
        bookType
        screenCapturable
        status
        coverResizeImgPath
        coverImgPath
        requireAgeVerify
      }
    }
  }
`
