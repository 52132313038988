import { gql } from 'graphql-request'

export const BOOK_CHAPTER_META_DATA = gql`
  query BookChapterForShare($id: Int!) {
    bookChapterForShare(id: $id) {
      id
      chapterName
      chapterTitle
      chapterCoverImgPath
      book {
        id
        title
        bookType
        status
        coverImgPath
        cover
        facebookShareImageUrl
        twitterShareImageUrl
        category {
          id
          name
        }
        tags {
          id
          name
        }
        penName {
          id
          firstPenName
        }
      }
    }
  }
`
