import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { Expose } from 'class-transformer'

export class TableContentReaderType {
  @Expose() id: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() status: ChapterStatusEnum

  @Expose() isPaid: boolean
}
