export enum ChapterStatusEnum {
  RESTRICTED = 'RESTRICTED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export const ChapterStatusValue = {
  [ChapterStatusEnum.RESTRICTED]: 'ปิดเนื้อหา',
  [ChapterStatusEnum.PUBLISHED]: 'เผยแพร่',
  [ChapterStatusEnum.UNPUBLISHED]: 'ไม่เผยแพร่',
}
