import { EVENT_ACTION_ENUM } from '@configs/config'
import { PaymentMethodTypeEnum } from '@interfaces/PaymentMethodTypeEnum'
import { cleanFalsyValueFromObject } from './utils'

export function pageView() {
  window.fbq('track', 'PageView')
}

export function fbqAddCoin(value: {
  pay_type: PaymentMethodTypeEnum
  coin: number
  promo_code?: string
}) {
  const payload = cleanFalsyValueFromObject(value)
  window.fbq('trackCustom', EVENT_ACTION_ENUM.TOPUP, payload)
}

export function fbqBeginCheckout(value: {
  value: number
  items: {
    item_id: number
    price: number
  }[]
}) {
  window.fbq('track', EVENT_ACTION_ENUM.INITIATE_CEHCKOUT, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    currency: 'THB',
    num_items: value.items.length,
    value: value.value,
    content_type: 'product',
    contents: value.items.map(item => ({
      id: `${item.item_id}`,
      quantity: 1,
      item_price: item.price,
    })),
  })
}

export function fbqBuyChapter(value: {
  value: number
  items: {
    item_id: number
    price: number
  }[]
}) {
  window.fbq('track', EVENT_ACTION_ENUM.PURCHASE, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    content_type: 'product',
    currency: 'THB',
    value: value.value,
    num_items: value.items.length,
    contents: value.items.map(item => ({
      id: `${item.item_id}`,
      quantity: 1,
      item_price: item.price,
    })),
  })
}

export function fbqDonateCharacter(value: {
  transaction_id: string
  value: number
  items: { item_id: number; item_name: string; price: number }[]
}) {
  window.fbq('track', EVENT_ACTION_ENUM.PURCHASE, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    content_type: 'product',
    currency: 'THB',
    value: value.value,
    num_items: value.items.length,
    contents: value.items.map(item => ({
      id: `${item.item_id}`,
      quantity: 1,
      item_price: item.price,
    })),
  })
}

export function fbqBuyChapterList(value: {
  value: number
  items: {
    item_id: number
    price: number
  }[]
}) {
  fbqBuyChapter(value)
}

export function fbqRegisterComplete() {}
