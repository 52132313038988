import { MEASUREMENT_ID, EVENT_ACTION_ENUM } from '@configs/config'
import { PaymentMethodTypeEnum } from '@interfaces/PaymentMethodTypeEnum'
import { BookEnum } from '@interfaces/BookEnum'
import { cleanFalsyValueFromObject } from './utils'

export function event(
  action: EVENT_ACTION_ENUM,
  params: {
    send_to: string
    value?: number
    currency?: string
    transaction_id?: string
  }
) {
  window.gtag('event', action, params)
}

export const pageView = () => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', MEASUREMENT_ID)
  }
}

export function gtagAddCoin(params: {
  pay_type: PaymentMethodTypeEnum
  coin: number
  promo_code?: string
}) {
  if (MEASUREMENT_ID) {
    const payload = cleanFalsyValueFromObject(params)
    event(EVENT_ACTION_ENUM.TOPUP, {
      send_to: MEASUREMENT_ID,
      ...payload,
    })
  }
}

export function gtagDonateCharacter(value: {
  transaction_id: string
  value: number
  items: { item_id: number; item_name: string; price: number }[]
}) {
  window.gtag('event', EVENT_ACTION_ENUM.GTAG_PURCHASE, {
    send_to: MEASUREMENT_ID,
    transaction_id: value.transaction_id,
    value: value.value,
    currency: 'THB',
    items: value.items.map(item => ({
      item_id: item.item_id,
      item_variant: 'donate-character',
      price: item.price,
      quantity: 1,
    })),
  })
}

export function gtagBeginCheckout(value: {
  value: number
  items: {
    item_id: number
    item_category: number
    item_variant: BookEnum
    price: number
  }[]
}) {
  window.gtag('event', EVENT_ACTION_ENUM.BEGIN_CHECKOUT, {
    send_to: MEASUREMENT_ID,
    value: value.value,
    currency: 'THB',
    items: value.items.map(item => ({
      item_id: item.item_id,
      item_variant: item.item_variant?.toLowerCase(),
      item_category: item.item_category,
      price: item.price,
      quantity: 1,
    })),
  })
}

export function gtagBuyChapter(value: {
  transaction_id: string
  value: number
  items: {
    item_id: number
    item_category: number
    item_variant: BookEnum
    price: number
  }[]
}) {
  window.gtag('event', EVENT_ACTION_ENUM.GTAG_PURCHASE, {
    send_to: MEASUREMENT_ID,
    transaction_id: value.transaction_id,
    value: value.value,
    currency: 'THB',
    items: value.items.map(item => ({
      item_id: item.item_id,
      item_variant: item.item_variant?.toLowerCase(),
      item_category: item.item_category,
      price: item.price,
      quantity: 1,
    })),
  })
}

export function gtagBuyChapterList(value: {
  transaction_id: string
  value: number
  items: {
    item_id: number
    item_category: number
    item_variant: BookEnum
    price: number
  }[]
}) {
  gtagBuyChapter(value)
}
