import { plainToInstance } from 'class-transformer'
import { DateTime } from 'luxon'

import { UPLOAD_FILE } from '@client/collections/File/schemas/uploadFile'
import { CREATE_STORY } from '@client/collections/Story/schemas/createStory'
import { GET_BOOK_BY_USER_ID } from '@client/collections/Story/schemas/getBookByUserId'
import { GET_BOOK_STORY_SETTINGS } from '@client/collections/Story/schemas/getBookStorySetting'
import { GET_BOOK_TOP_CHARTS } from '@client/collections/Story/schemas/getBookTopChart'
import { GET_CHARACTERS } from '@client/collections/Story/schemas/getCharacters'
import { GET_STORY } from '@client/collections/Story/schemas/getStory'
import { GET_STORY_DETAIL } from '@client/collections/Story/schemas/getStoryDetail'
import { GET_STORY_FOR_SHARE } from '@client/collections/Story/schemas/getStoryForShare'
import {
  GET_STORY_READER,
  GET_STORY_READER_BY_IDS,
} from '@client/collections/Story/schemas/getStoryReader'
import { TOGGLE_BOOK_LIKE } from '@client/collections/Story/schemas/toggleBookLike'
import { UPDATE_STORY } from '@client/collections/Story/schemas/updateStory'
import { gqlApiInstance } from '@client/init'
import { BookEnum } from '@interfaces/BookEnum'
import { clearComma } from '@lib/utils'
import { BookByUserResponse } from '@models/book/BookByUserResponse'
import { BookCharacterType } from '@models/book/BookCharacterType'
import { LikeSuccessStatusEnum } from '@models/book/LikeSuccessStatusEnum'
import { BookLinkType } from '@models/myWriting/BookLinkType'
import { CharacterType } from '@models/myWriting/CharacterType'
import { BookStorySettingType } from '@models/story/BookStorySettingType'
import { BookTopChartsResponse } from '@models/story/BookTopChartsResponse'
import { StoryDetailReaderType } from '@models/story/StoryDetailReaderType'
import { StoryDetailType } from '@models/story/StoryDetailType'
import { StoryFormType } from '@models/story/StoryFormType'
import { StoryForShareType } from '@models/story/StoryForShareType'
import { useCharacterAction } from '@hooks/character/useCharacterAction'
import { useBookLinkAction } from '@hooks/bookLink/useBookLinkAction'
import { BookTopChartEnum } from '@interfaces/BookTopChartEnum'
import { TopChartEnum } from '@interfaces/TopChartEnum'
import { ResizeTypeEnum } from '@interfaces/ResizeTypeEnum'

export interface BookTopChartsParam {
  limit: number
  categoryId?: string
  startDate: DateTime
  endDate: DateTime
  bookType: BookEnum
  topChartType: TopChartEnum
  bookTopChartType: BookTopChartEnum
}

interface BookTopChartsQuery extends BookTopChartsParam {
  page: number
}

export function useBookAction() {
  const characterClient = useCharacterAction()
  const bookLinkClient = useBookLinkAction()

  async function getStoryById(id: number): Promise<StoryFormType> {
    const { book } = await gqlApiInstance.request(GET_STORY, { id })

    return plainToInstance(StoryFormType, book)
  }

  async function getStoryDetail(id: number): Promise<StoryDetailType> {
    const { book } = await gqlApiInstance.request(GET_STORY_DETAIL, { id })

    return plainToInstance(StoryDetailType, book)
  }

  async function getCharacters(id: number): Promise<BookCharacterType[]> {
    const { getCharacterByBook } = await gqlApiInstance.request(
      GET_CHARACTERS,
      {
        bookId: id,
      }
    )

    return plainToInstance(
      BookCharacterType,
      getCharacterByBook as Array<Record<string, any>>
    )
  }

  async function getStoryForShare(id: number): Promise<StoryForShareType> {
    const { getBookReader } = await gqlApiInstance.request(
      GET_STORY_FOR_SHARE,
      {
        getBookReaderId: id,
      }
    )

    return plainToInstance(StoryForShareType, getBookReader)
  }

  async function getStoryReader(id: number): Promise<StoryDetailReaderType> {
    const { getBookReader } = await gqlApiInstance.request(GET_STORY_READER, {
      getBookReaderId: id,
    })

    return plainToInstance(StoryDetailReaderType, getBookReader)
  }

  async function getStoryReaderByIds(
    ids: number[]
  ): Promise<StoryDetailReaderType[]> {
    try {
      const { getBookReaderByIds } = await gqlApiInstance.request(
        GET_STORY_READER_BY_IDS,
        {
          getBookReaderIds: ids,
        }
      )

      return getBookReaderByIds.map((book: StoryDetailReaderType) => {
        return plainToInstance(StoryDetailReaderType, book)
      })
    } catch (error) {
      return []
    }
  }

  async function getBookTopCharts({
    limit,
    categoryId,
    bookType,
    topChartType,
    bookTopChartType,
    page,
    ...params
  }: BookTopChartsQuery): Promise<BookTopChartsResponse> {
    const startDate = params.endDate.startOf('day').toUTC()
    const endDate = startDate.plus({ hour: 8, day: 1 })
    const { bookTopCharts } = await gqlApiInstance.request(
      GET_BOOK_TOP_CHARTS,
      {
        limitPerPage: limit,
        page,
        bookType,
        ...(categoryId && { categoryId: Number(categoryId) }),
        startDate,
        endDate,
        topChartsType: topChartType,
        bookTopChartsType: bookTopChartType,
      }
    )

    return plainToInstance(BookTopChartsResponse, bookTopCharts)
  }

  async function uploadFile(
    file: File | Blob,
    resizeType?: ResizeTypeEnum
  ): Promise<{
    filePath: string
    filePathResize: string
  }> {
    const {
      UploadBookCover: { filePath, filePathResize },
    } = await gqlApiInstance.request(UPLOAD_FILE, {
      file,
      resizeType,
    })

    return { filePath, filePathResize }
  }

  async function createBookLinks(
    links: BookLinkType[],
    bookId: number
  ): Promise<void> {
    const data = links.map(({ detail, link }) => ({
      detail,
      link,
      bookId,
    }))
    await bookLinkClient.createBookLinks(data)
  }

  async function updateBookLinks(
    links: BookLinkType[],
    bookId: number
  ): Promise<BookLinkType[]> {
    let createResponse: BookLinkType[] = []
    let updateResponse: BookLinkType[] = []
    const createData = links.reduce(
      (res, cur) => {
        if (!cur.id) {
          return [
            ...res,
            {
              link: cur.link,
              detail: cur.detail,
              bookId,
            },
          ]
        }

        return res
      },
      [] as {
        link: string
        detail: string
        bookId: number
      }[]
    )
    const updateData = links.filter(row => row.id && !row.isDeleted)
    const removeData = links.reduce((res, cur) => {
      if (cur.isDeleted && cur.id) {
        return [...res, cur.id]
      }

      return res
    }, [] as number[])

    if (createData.length) {
      createResponse = await bookLinkClient.createBookLinks(createData)
    }

    if (updateData.length) {
      updateResponse = await bookLinkClient.updateBookLinks(updateData)
    }

    if (removeData.length) {
      await bookLinkClient.removeBookLinks(removeData)
    }

    return [...updateResponse, ...createResponse]
  }

  async function createCharacters(
    characters: CharacterType[],
    bookId: number
  ): Promise<CharacterType[]> {
    const response = await characterClient.createCharacters(characters, bookId)

    return response
  }

  async function updateCharacters(
    characters: CharacterType[],
    bookId: number
  ): Promise<any> {
    let createResponse: CharacterType[] = []
    let updateResponse: CharacterType[] = []

    const deleteCharacterData = characters.filter(
      row => row.id && row.isDeleted
    )

    if (deleteCharacterData.length) {
      await characterClient.bulkRemoveCharacter(
        deleteCharacterData.map(row => row.id!)
      )
    }

    const createCharacterData = characters.filter(
      row => !row.id && !row.isEmptyForm
    )
    if (createCharacterData.length) {
      createResponse = await createCharacters(createCharacterData, bookId)
    }

    const updateCharacterData = characters.filter(
      row => row.id && !row.isEmptyForm && !row.isDeleted
    )
    if (updateCharacterData.length) {
      updateResponse = await characterClient.bulkUpdateCharacters(
        updateCharacterData,
        bookId
      )
    }

    return updateResponse.concat(createResponse)
  }

  async function createStory(form: StoryFormType): Promise<number> {
    let coverImgPath = {
      filePath: form.coverImgPath.url,
      filePathResize: form.coverResizeImgPath,
    }
    let naiinCoverImgPath = { filePath: form.naiinCoverImgPath.url }

    try {
      if (form.coverImgPath?.blob) {
        coverImgPath = await uploadFile(
          form.coverImgPath.blob,
          ResizeTypeEnum.BOOK
        )
      }

      if (form.naiinCoverImgPath?.blob) {
        naiinCoverImgPath = await uploadFile(form.naiinCoverImgPath.blob)
      }

      const { createBook } = await gqlApiInstance.request(CREATE_STORY, {
        createBookInput: {
          bookType: form.bookType,
          chapterType: form.chapterType,
          ...(coverImgPath.filePath && { coverImgPath: coverImgPath.filePath }),
          ...(coverImgPath.filePathResize && {
            coverResizeImgPath: coverImgPath.filePathResize,
          }),
          title: form.title,
          cover: form.cover,
          writingType: form.writingType,
          isTranslated: form.isTranslated,
          ...(form.isTranslated && { writer: form.writer }),
          categoryId: form.categoryId,
          ratingId: form.ratingId,
          penNameId: form.penNameId,
          intro: form.intro,
          tagNames: form.tags,
          ...(naiinCoverImgPath.filePath && {
            naiinCoverImgPath: naiinCoverImgPath.filePath,
          }),
          naiinTitle: form.naiinTitle,
          naiinCover: form.naiinCover,
          naiinLink: form.naiinLink,
          screenCapturable: form.screenCapturable,
          offlineReadable: form.offlineReadable,
          commentable: form.commentable,
          guestCommentable: form.guestCommentable,
          orientation: form.orientation,
          isEnded: form.isEnded,
          isEpub: form.isEpub,
          isPdf: form.isPdf,
          isEbook: false,
          ...(form.isEnded && {
            price: clearComma(form.price),
          }),
          bookStory: form.bookStory
            .filter(item => item.content)
            .map(item => ({ name: item.name, content: item.content })),
          status: form.status,
          requireAgeVerify: form.requireAgeVerify,
        },
      })

      if (form.characters?.length && createBook.id) {
        const charactersForm = form.characters.filter(row => !row.isEmptyForm)
        await createCharacters(charactersForm, createBook.id)
      }

      return createBook.id
    } catch (error) {
      throw error
    }
  }

  async function updateStory(form: StoryFormType): Promise<StoryFormType> {
    let coverImgPath = {
      filePath: form.coverImgPath.url,
      filePathResize: form.coverResizeImgPath,
    }
    let naiinCoverImgPath = { filePath: form.naiinCoverImgPath.url }
    let characters: CharacterType[] = []
    const bookId = form.id

    try {
      if (form.coverImgPath?.blob) {
        coverImgPath = await uploadFile(
          form.coverImgPath.blob,
          ResizeTypeEnum.BOOK
        )
      }

      if (form.naiinCoverImgPath?.blob) {
        naiinCoverImgPath = await uploadFile(form.naiinCoverImgPath.blob)
      }

      const { updateBook } = await gqlApiInstance.request(UPDATE_STORY, {
        updateBookInput: {
          id: form.id,
          status: form.status,
          bookType: form.bookType,
          chapterType: form.chapterType,
          ...(coverImgPath.filePath && { coverImgPath: coverImgPath.filePath }),
          ...(coverImgPath.filePathResize && {
            coverResizeImgPath: coverImgPath.filePathResize,
          }),
          title: form.title,
          cover: form.cover,
          writingType: form.writingType,
          isTranslated: form.isTranslated,
          ...(form.isTranslated && { writer: form.writer }),
          categoryId: form.categoryId,
          ratingId: form.ratingId,
          penNameId: form.penNameId,
          intro: form.intro,
          tagNames: form.tags,
          ...(naiinCoverImgPath.filePath && {
            naiinCoverImgPath: naiinCoverImgPath.filePath,
          }),
          naiinTitle: form.naiinTitle,
          naiinCover: form.naiinCover,
          naiinLink: form.naiinLink,
          screenCapturable: form.screenCapturable,
          offlineReadable: form.offlineReadable,
          commentable: form.commentable,
          guestCommentable: form.guestCommentable,
          orientation: form.orientation,
          isEnded: form.isEnded,
          isEpub: form.isEpub,
          isPdf: form.isPdf,
          isEbook: false,
          ...(form.isEnded && {
            price: clearComma(form.price),
          }),
          bookStory: form.bookStory
            .filter(item => item.content)
            .map(item => ({ name: item.name, content: item.content })),
          requireAgeVerify: form.requireAgeVerify,
        },
      })

      if (form.characters?.length && bookId) {
        characters = await updateCharacters(form.characters, bookId)
      }

      return plainToInstance(StoryFormType, {
        ...updateBook,
        characters,
      })
    } catch (error) {
      throw error
    }
  }

  async function toggleBookLike(
    bookId: number
  ): Promise<LikeSuccessStatusEnum> {
    const response = await gqlApiInstance.request(TOGGLE_BOOK_LIKE, {
      input: {
        bookId,
      },
    })

    return response.toggleBookLike.type
  }

  async function getBooksByUserId({
    id,
    bookType,
    penNameId,
    page,
    limitPerPage,
  }: {
    id: number
    bookType: BookEnum | null
    penNameId: number | null
    page: number
    limitPerPage: number
  }): Promise<BookByUserResponse> {
    const { getBookByUserId } = await gqlApiInstance.request(
      GET_BOOK_BY_USER_ID,
      {
        id,
        bookType,
        penNameId,
        page,
        limitPerPage,
      }
    )

    return plainToInstance(BookByUserResponse, getBookByUserId)
  }

  async function getBookStorySettings(): Promise<BookStorySettingType[]> {
    const { bookStorySettings } = await gqlApiInstance.request(
      GET_BOOK_STORY_SETTINGS
    )

    return plainToInstance(BookStorySettingType, <any[]>bookStorySettings)
  }

  return {
    getStoryById,
    getStoryDetail,
    getCharacters,
    getStoryForShare,
    getStoryReader,
    getBookTopCharts,
    createBookLinks,
    updateBookLinks,
    createStory,
    updateStory,
    toggleBookLike,
    getBooksByUserId,
    getBookStorySettings,
    getStoryReaderByIds,
  }
}
