import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { DateTime } from 'luxon'

import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { FacebookIcon } from '@components/icons/FacebookIcon'
import { TwitterIcon } from '@components/icons/TwitterIcon'
import { SHOW_EBOOK_FEATURE } from '@configs/config'
import { FooterAccordtion } from './FooterAccordtion'

const menus = [
  {
    id: 1,
    title: 'จักรวาล MaReads',
    subMenus: SHOW_EBOOK_FEATURE
      ? [
          { text: 'นิยายรายตอน', link: '/all-category?bookType=NOVEL' },
          { text: 'การ์ตูน', link: '/all-category?bookType=MANGA' },
          { text: 'อีบุ๊ก', link: '/all-category-ebook' },
          { text: 'ท็อปชาร์ตนิยาย/การ์ตูน', link: '/top-view' },
          { text: 'ท็อปชาร์ตตัวละคร', link: '/character/top' },
        ]
      : [
          { text: 'นิยายรายตอน', link: '/all-category?bookType=NOVEL' },
          { text: 'การ์ตูน', link: '/all-category?bookType=MANGA' },
          { text: 'ท็อปชาร์ตนิยาย/การ์ตูน', link: '/top-view' },
          { text: 'ท็อปชาร์ตตัวละคร', link: '/character/top' },
        ],
  },
  {
    id: 2,
    title: 'รู้จัก MaReads',
    subMenus: [
      { text: 'เกี่ยวกับเรา', link: '/about-us' },
      { text: 'คำถามที่พบบ่อย (FAQ)', link: '/faq' },
      { text: 'ติดต่อเรา', link: '/contact-us' },
    ],
  },
  {
    id: 3,
    title: 'นโยบาย',
    subMenus: [
      { text: 'นโยบายส่วนบุคคล', link: '/policy' },
      { text: 'เงื่อนไขการใช้บริการ', link: '/terms-of-use' },
    ],
  },
]

export function Footer() {
  const { theme } = useTheme()
  const router = useRouter()
  const { push } = router

  function goToHomePage() {
    push('/')
  }

  return (
    <footer
      className={cn('bg-gray-4 mt-auto', {
        'bg-dark': [ThemeEnum.NIGHT, ThemeEnum.DARK].includes(theme),
      })}
    >
      <div className='flex justify-center'>
        <div className='flex tablet-mobile:flex-col py-[50px] tablet-mobile:py-[20px] tablet-mobile:px-[20px] justify-between w-full max-w-[1160px]'>
          <div className='flex flex-col tablet:flex-row justify-between'>
            <div className='flex flex-col tablet-mobile:flex-row tablet-mobile:items-center tablet-mobile:space-x-[150px]'>
              <Image
                onClick={goToHomePage}
                className={cn('cursor-pointer', {
                  'opacity-50': theme === ThemeEnum.DARK,
                })}
                src={
                  [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme)
                    ? '/logo.webp'
                    : '/logo-light.webp'
                }
                alt='Mareads'
                width={125}
                height={40}
              />

              <div
                className={cn('flex space-x-[10px] my-[20px]', {
                  'opacity-50': theme === ThemeEnum.DARK,
                })}
              >
                <a
                  href='https://www.facebook.com/WebMareads'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Mareads on Facebook'
                >
                  <FacebookIcon width='30' height='30' />
                </a>
                <a
                  href='https://twitter.com/WebMareads'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Mareads on Twitter'
                >
                  <TwitterIcon width='30' height='30' />
                </a>
              </div>
            </div>
            <div
              className={cn(
                'flex flex-col tablet-mobile:flex-row tablet-mobile:items-center tablet-mobile:space-x-[20px] gap-y-2',
                {
                  'opacity-50': theme === ThemeEnum.DARK,
                }
              )}
            >
              <a href='https://apps.apple.com/th/app/mareads-%E0%B8%A1%E0%B8%B2%E0%B8%A3-%E0%B8%94/id1625633273?l=th'>
                <div className='cursor-pointer mobile:hidden'>
                  <img
                    src='/download-app-store.webp'
                    alt='Mareads'
                    width={106}
                    height={32}
                  />
                </div>
                <div className='cursor-pointer desktop-tablet:hidden'>
                  <img
                    src='/download-app-store.webp'
                    alt='Mareads'
                    width={164}
                    height={50}
                  />
                </div>
              </a>

              <a href='https://play.google.com/store/apps/details?id=com.amarin.mareads.prd'>
                <div className='cursor-pointer mobile:hidden'>
                  <img
                    src='/download-google-play.webp'
                    alt='Mareads'
                    width={106}
                    height={32}
                  />
                </div>
                <div className='cursor-pointer desktop-tablet:hidden'>
                  <img
                    src='/download-google-play.webp'
                    alt='Mareads'
                    width={164}
                    height={50}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className='flex flex-1 space-x-[100px] desktop:ml-[100px] tablet:mt-[40px] mobile:hidden'>
            {menus.map(menu => (
              <div key={menu.title} className='flex flex-col space-y-[6px]'>
                <p
                  className={cn(
                    'font-mitr font-medium mb-[10px] dark:text-white',
                    {
                      'text-secondary': [
                        ThemeEnum.LIGHT,
                        ThemeEnum.SEPIA,
                      ].includes(theme),
                      'text-gray-16': theme === ThemeEnum.DARK,
                    }
                  )}
                >
                  {menu.title}
                </p>
                {menu.subMenus.map(subMenu => (
                  <Link href={subMenu.link} key={subMenu.text} prefetch={false}>
                    <a
                      className={cn('text-[14px] font-light dark:text-white', {
                        'text-secondary-1': [
                          ThemeEnum.LIGHT,
                          ThemeEnum.SEPIA,
                        ].includes(theme),
                        'text-gray-16': theme === ThemeEnum.DARK,
                      })}
                    >
                      {subMenu.text}
                    </a>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <FooterAccordtion footerOption={menus} />
        </div>
      </div>
      <div
        className={cn(
          'flex bg-primary text-[12px] font-light py-[20px] justify-center',
          {
            'bg-dark border-t border-dark-3': [
              ThemeEnum.NIGHT,
              ThemeEnum.DARK,
            ].includes(theme),
            'text-white': theme !== ThemeEnum.DARK,
            'text-gray-16': theme === ThemeEnum.DARK,
          }
        )}
      >
        © 2021 - {DateTime.now().year} Amarin Book Center | All Rights Reserved.
      </div>
    </footer>
  )
}
