import { Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export const rateRIds = [3, 4]

export function Default(defaultValue: any) {
  return Transform(({ value }: any) =>
    value !== null && value !== undefined && value !== '' ? value : defaultValue
  )
}

export function kFormatter(num: number): string {
  const kFormat = Math.sign(num) * (Math.abs(num) / 1000)
  return Math.abs(num) > 999
    ? `${kFormat % 1 === 0 ? kFormat.toFixed(0) : kFormat.toFixed(1)}K`
    : `${Math.sign(num) * Math.abs(num)}`
}

export function abbreviateNumber(value: number, digit = 1): string {
  const defaultSymbols = ['', 'K', 'M', 'G', 'T', 'P', 'E']

  if (value <= 0) return value.toString()

  const tier = Math.floor(Math.log10(value) / 3)

  if (tier === 0) return value.toString()

  const scale = 10 ** (tier * 3)
  const scaled = value / scale

  const [number, decimal] = String(scaled).split('.')

  const res = `${number}${
    decimal && digit ? `.${decimal.slice(0, digit)}` : ''
  }`

  return res + defaultSymbols[tier]
}

export function diffNow(
  dateTime: string,
  suffix = '',
  showDateAfterOneDay = false,
  isShowTime = false
): string {
  const dt = DateTime.fromISO(dateTime)
  let diffStr = ''
  if (dt) {
    const { minutes } = dt.diffNow('minutes')
    const { hours } = dt.diffNow('hours')
    const { days } = dt.diffNow('days')
    const { months } = dt.diffNow('months')
    const { years } = dt.diffNow('years')

    diffStr =
      Math.abs(Math.round(hours)) > 23 && showDateAfterOneDay
        ? `${formatDateDomini(dateTime, isShowTime)}${isShowTime ? ' น.' : ''}`
        : Math.abs(Math.round(minutes)) < 1
        ? `เมื่อสักครู่`
        : Math.abs(Math.round(minutes)) < 60
        ? `${Math.abs(minutes).toFixed(0)} นาที${suffix}`
        : Math.abs(Math.round(hours)) < 24
        ? `${Math.abs(hours).toFixed(0)} ชม. ${suffix}`
        : Math.abs(Math.round(months)) < 1
        ? `${Math.abs(days).toFixed(0)} วัน${suffix}`
        : Math.abs(Math.round(months)) < 12
        ? `${Math.abs(months).toFixed(0)} เดือน${suffix}`
        : `${Math.abs(years).toFixed(0)} ปี${suffix}`
  }
  return diffStr
}

export function timeSince(datetime: string): string {
  const dateNow = new Date(datetime)
  const dt = DateTime.fromJSDate(dateNow)
  let diffStr = ''

  if (dt) {
    const { minutes } = dt.diffNow('minutes')
    const { hours } = dt.diffNow('hours')

    const [day, month, year] = dateNow
      .toLocaleTimeString('th-TH', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      .split(' ')

    diffStr =
      Math.floor(Math.abs(minutes)) < 1
        ? `เมื่อสักครู่`
        : Math.floor(Math.abs(minutes)) < 60
        ? `ใช้งานล่าสุด ${Math.abs(minutes).toFixed(0)} นาทีที่แล้ว`
        : Math.floor(Math.abs(hours)) < 24
        ? `ใช้งานล่าสุด ${Math.abs(hours).toFixed(0)} ชั่วโมงที่แล้ว`
        : `ใช้งานล่าสุด ${day} ${month} ${year}`
  }

  return diffStr
}

export function formatDateTime(date: any) {
  return new Date(date).toLocaleTimeString('th-TH', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function getErrorMessage(error: any) {
  let msg = 'Something wrong.'

  if (error.response?.errors?.length) {
    msg = error.response.errors[0].message
  } else if (error.message) {
    msg = error.message
  }

  if (
    msg.includes('Could not find any entity of type "BookChapter" matching:')
  ) {
    msg = 'ไม่พบเนื้อหานี้'
  }

  return msg
}

export function addComma(value: number, digit = 0) {
  const integer = value.toLocaleString().split('.')[0]
  const decimal = value.toFixed(digit).split('.')[1]

  return digit ? `${integer}.${decimal}` : integer
}

export function showCommaFromString(value?: string) {
  if (!value) return '-'

  const [integer, decimal] = value.split('.')

  return `${Number(integer).toLocaleString()}.${decimal}`
}

export function clearComma(value: string) {
  return Number(value.replace(/,/g, ''))
}

export function formatDateDomini(
  date: string,
  isShowTime = false,
  isFullYear = true
) {
  if (!date) return '-'

  const d = DateTime.fromISO(date)
  const t = d.setLocale('th')
  const yearFormat = isFullYear ? 'yyyy' : 'yy'
  let text = `${d.toFormat('dd')} ${t.toFormat('LLL')} ${d.toFormat(
    yearFormat
  )}`

  if (isShowTime) {
    text += ` ${d.toFormat('HH:mm')}`
  }

  return text
}

export function formatDate({
  date,
  isShowTime = false,
  isFullMonth = false,
  isFullYear = true,
}: {
  date: string
  isShowTime?: boolean
  isFullMonth?: boolean
  isFullYear?: boolean
}) {
  if (!date) return '-'

  const d = DateTime.fromISO(date)
  const t = d.setLocale('th')
  const yearFormat = isFullYear ? 'yyyy' : 'yy'
  const monthFormat = isFullMonth ? 'LLLL' : 'LLL'
  let text = `${d.toFormat('dd')} ${t.toFormat(monthFormat)} ${d.toFormat(
    yearFormat
  )}`

  if (isShowTime) {
    text += ` ${d.toFormat('HH:mm')}`
  }

  return text
}

export function formatDatetime(date: string) {
  const d = DateTime.fromISO(date)
  const t = d.setLocale('th')

  return `${d.toFormat('dd')} ${t.toFormat('LLL')} ${d.toFormat(
    'yyyy'
  )} - ${d.toFormat('HH:mm:ss')}`
}

export function scrollToElement(selectors: string) {
  const el = document.querySelector(selectors)

  if (el) {
    window.scrollTo({
      top:
        el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        80,
    })
  }
}

export function checkCurrentDate(
  value: Date,
  currentDate: Date,
  isReturnEndOfDay?: boolean
) {
  if (value) {
    return value.getDate() === currentDate.getDate() &&
      value.getMonth() === currentDate.getMonth() &&
      value.getFullYear() === currentDate.getFullYear()
      ? isReturnEndOfDay
        ? new Date(new Date().setHours(23, 59, 59))
        : currentDate
      : undefined
  }

  return undefined
}

export function diffDate(value1: Date, value2: Date) {
  if (value1.getFullYear() > value2.getFullYear()) {
    return 1
  }

  if (value1.getFullYear() < value2.getFullYear()) {
    return -1
  }

  if (value1.getMonth() > value2.getMonth()) {
    return 1
  }

  if (value1.getMonth() < value2.getMonth()) {
    return -1
  }

  if (value1.getDate() > value2.getDate()) {
    return 1
  }

  if (value1.getDate() < value2.getDate()) {
    return -1
  }

  return 0
}

export function roundToTwo(numb: number) {
  return Math.round((numb + Number.EPSILON) * 100) / 100
}

export const DAY = 86400000

export function formatAvgRating(value: number) {
  const decimalPart = value.toString().split('.')[1]

  if (decimalPart && decimalPart.length >= 2) {
    const secondDecimal = Number(decimalPart[1])

    if (secondDecimal >= 1 && secondDecimal <= 5) {
      return Math.floor(value * 10) / 10
    }
    if (secondDecimal >= 6 && secondDecimal <= 9) {
      return Math.ceil(value * 10) / 10
    }
  }

  return value.toFixed(1)
}

export function cleanFalsyValueFromObject(
  obj: Record<string, any>,
  format?: (value: any) => any
): Record<string, any> {
  try {
    const newObj: Record<string, any> = {}

    Object.keys(obj).forEach(key => {
      const value = obj[key]

      if (Array.isArray(value)) {
        const cleanedArray = value
          .map(item => {
            if (item && typeof item === 'object') {
              return cleanFalsyValueFromObject(item, format)
            }
            return item
          })
          .filter(item => {
            return !(Array.isArray(item)
              ? item.length === 0
              : item === null || item === undefined || item === '')
          })

        if (cleanedArray.length > 0) {
          newObj[key] = cleanedArray
        }
      } else if (value && typeof value === 'object') {
        // Recurse into nested objects
        const cleanedValue = cleanFalsyValueFromObject(value, format)

        if (Object.keys(cleanedValue).length > 0) {
          newObj[key] = cleanedValue
        }
      } else if (value !== null && value !== undefined && value !== '') {
        newObj[key] = format ? format(value) : value
      }
    })

    return newObj
  } catch {
    return obj
  }
}
