import { EVENT_ACTION_ENUM } from '@configs/config'
import { PaymentMethodTypeEnum } from '@interfaces/PaymentMethodTypeEnum'
import { cleanFalsyValueFromObject } from './utils'

export function pageView() {
  if (typeof window !== 'undefined' && window.ttq) {
    window.ttq.page()
  }
}

export function ttqAddCoin(value: {
  pay_type: PaymentMethodTypeEnum
  coin: number
  promo_code?: string
}) {
  const payload = cleanFalsyValueFromObject(value)
  window.ttq.track(EVENT_ACTION_ENUM.TOPUP, payload)
}

export function ttqBeginCheckout(value: {
  value: number
  items: {
    item_id: number
    item_name: string
    item_category: number
    price: number
  }[]
}) {
  window.ttq.track(EVENT_ACTION_ENUM.INITIATE_CEHCKOUT, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    currency: 'THB',
    num_items: value.items.length,
    value: value.value,
    content_type: 'product',
    contents: value.items.map(item => ({
      content_id: `${item.item_id}`,
      content_name: item.item_name,
      value: item.price,
      num_items: 1,
      currency: 'THB',
    })),
  })
}

export function ttqBuyChapter(value: {
  value: number
  items: {
    item_id: number
    item_name: string
    price: number
  }[]
}) {
  window.ttq.track(EVENT_ACTION_ENUM.PURCHASE, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    content_type: 'product',
    currency: 'THB',
    value: value.value,
    num_items: value.items.length,
    contents: value.items.map(item => ({
      content_id: `${item.item_id}`,
      content_name: item.item_name,
      num_items: 1,
      value: item.price,
      currency: 'THB',
    })),
  })
}

export function ttqBuyChapterList(value: {
  value: number
  items: {
    item_id: number
    item_name: string
    price: number
  }[]
}) {
  ttqBuyChapter(value)
}

export function ttqDonateCharacter(value: {
  transaction_id: string
  value: number
  items: { item_id: number; item_name: string; price: number }[]
}) {
  window.ttq.track(EVENT_ACTION_ENUM.PURCHASE, {
    content_ids:
      value.items.length > 1
        ? value.items.map(item => `${item.item_id}`)
        : `${value.items[0].item_id}`,
    content_type: 'product',
    currency: 'THB',
    value: value.value,
    contents: value.items.map(item => ({
      content_id: `${item.item_id}`,
      content_name: item.item_name,
      value: item.price,
      num_items: 1,
      currency: 'THB',
    })),
    num_items: value.items.length,
  })
}
