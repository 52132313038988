import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { BookPreviewType } from '@models/chapter/BookPreviewType'
import { ChapterPagePreviewType } from './ChapterPagePreviewType'

@Exclude()
export class BookChapterPreviewType {
  @Expose() id: number

  @Expose() prevId: number | null

  @Expose() nextId: number | null

  @Expose() chapterName: string

  @Expose() commentCount: number

  @Expose() lastCommentDate: string

  @Expose() commentable: boolean

  @Expose() chapterTitle: string

  @Expose()
  @Transform(({ value }) => (value === '<p></p>' ? '' : value))
  writerMessage: string

  @Expose()
  status: ChapterStatusEnum

  @Expose()
  @Type(() => ChapterPagePreviewType)
  chapterPages: ChapterPagePreviewType[]

  @Expose()
  @Type(() => BookPreviewType)
  book: BookPreviewType

  @Expose() isPaid: boolean

  @Expose() price: number

  @Expose() isUserHasBlockedComment: boolean

  @Expose() chapterCoverImgPath: string

  @Expose() passReadPromotion: boolean

  @Expose() publishedAt: string
}
