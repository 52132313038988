import { gql } from 'graphql-request'

export const UPDATE_BOOK_CHAPTER_STATUS = gql`
  mutation UpdateBookChapter($updateBookChapterInput: UpdateBookChapterInput!) {
    updateBookChapter(updateBookChapterInput: $updateBookChapterInput) {
      id
      status
      publishedAt
    }
  }
`
