import { gql } from 'graphql-request'

export const BOOK_CHAPTERS = gql`
  query BookChapters($ids: [Int!], $page: Int, $limitPerPage: Int) {
    bookChapters(ids: $ids, page: $page, limitPerPage: $limitPerPage) {
      data {
        id
        chapterName
        status
        book {
          id
          writer
          status
          title
          coverImgPath
          coverResizeImgPath
          penName {
            user {
              id
              profilePageSlug
            }
            firstPenName
          }
        }
      }
    }
  }
`

export const BOOK_CHAPTER_OPTIONS = gql`
  query GetBookChapters($page: Int, $limitPerPage: Int, $bookId: Int) {
    getBookChapters(page: $page, limitPerPage: $limitPerPage, bookId: $bookId) {
      page
      data {
        id
        chapterName
        chapterTitle
        status
      }
    }
  }
`
export const BOOK_CHAPTERS_READER_PAGINATE = gql`
  query GetBookChaptersReaderPaginateWithSummaryPrice(
    $limitPerPage: Int
    $page: Int
    $bookId: Int!
    $onlyPaid: Boolean
  ) {
    getBookChaptersReaderPaginateWithSummaryPrice(
      limitPerPage: $limitPerPage
      page: $page
      bookId: $bookId
      onlyPaid: $onlyPaid
    ) {
      data {
        id
        runningNo
        chapterName
        chapterTitle
        viewCount
        commentCount
        price
        status
        createdAt
        updatedAt
        publishedAt
        isPaid
        freeReadEndTime
        chapterCoverImgPath
        isBookmarked
      }
      total
      page
    }
  }
`

export const BUY_ALL_BOOK_CHAPTERS_READER = gql`
  query GetBookChaptersReader($bookId: Int!, $onlyPaid: Boolean) {
    getBookChaptersReader(bookId: $bookId, onlyPaid: $onlyPaid) {
      id
      chapterName
      chapterTitle
      price
    }
  }
`

export const GET_CHAPTER_LIST = gql`
  query GetBookChapters($page: Int, $bookId: Int, $limitPerPage: Int) {
    getBookChapters(page: $page, bookId: $bookId, limitPerPage: $limitPerPage) {
      data {
        id
        runningNo
        chapterName
        chapterTitle
        chapterCoverImgPath
        viewCount
        commentCount
        price
        status
        publishedAt
        purchasedCount
        totalSales
      }
      total
    }
  }
`
