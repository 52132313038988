import { DateTime } from 'luxon'
import { PromotionChallengeActionEnum } from '@interfaces/PromotionChallengeActionEnum'

export const promotionChallengeActionText = (text?: string) => {
  switch (text) {
    case PromotionChallengeActionEnum.LOVE:
      return '“กดหัวใจ”'
    case PromotionChallengeActionEnum.COMMENT:
      return '“แสดงความคิดเห็น”'
    case PromotionChallengeActionEnum.SHARE:
      return '“การแชร์”'
    case PromotionChallengeActionEnum.FINISH_PROFILE:
      return '“กรอกข้อมูลโปรไฟล์ครบ”'
    case PromotionChallengeActionEnum.READ:
      return '“อ่านเรื่อง”'
    case PromotionChallengeActionEnum.UNLOCK_CHAPTER:
      return '“ปลดล็อกนิยาย”'
    case PromotionChallengeActionEnum.DONATE:
      return '“สนับสนุนตัวละคร”'
    case PromotionChallengeActionEnum.SET_PRICE_CHAPTER:
      return '“เพิ่มตอนติดเหรียญ”'
    case PromotionChallengeActionEnum.CREATE_BOOK:
      return '“สร้างเรื่องใหม่”'
    default:
      return '-'
  }
}

export const promotionDate = (
  start: string,
  end: string,
  prefix = 'ตั้งแต่',
  showTime = true,
  parenthesis = true
) => {
  const utcNow = DateTime.now().toUTC()
  const now = utcNow.valueOf()
  const startDateTime = DateTime.fromISO(start)
  const endDateTime = DateTime.fromISO(end)
  const startOfDay = startDateTime.startOf('day').toUTC().valueOf()
  const endOfDay = endDateTime.endOf('day').toUTC().valueOf()
  const endTime = endDateTime.toFormat('HH:mm')
  const isMidNight = endTime === '00:00'
  let suffix = ''

  if (showTime) {
    if (isMidNight) {
      suffix = parenthesis ? '(สิ้นสุด 23:59 น.)' : 'สิ้นสุด 23:59 น.'
    } else {
      suffix = parenthesis
        ? `(สิ้นสุด ${endDateTime.toFormat('HH:mm')} น.)`
        : `สิ้นสุด ${endDateTime.toFormat('HH:mm')} น.`
    }
  }

  const localeDate = (date: string) => {
    const newDate = new Date(date)
    return `${newDate.toLocaleDateString('th-TH', {
      day: 'numeric',
      month: 'short',
    })} ${newDate.getFullYear().toString().slice(-2)}`
  }

  const endDate = isMidNight ? endDateTime.minus({ days: 1 }) : endDateTime

  if (now >= startOfDay && now < endOfDay) {
    const isTheSameDay = utcNow.hasSame(endDate, 'day')

    if (isTheSameDay) {
      return suffix ? `ภายในวันนี้ ${suffix}` : 'ภายในวันนี้'
    }

    const date = `วันนี้ - ${localeDate(endDate.toISO())}`
    return suffix ? `${date} ${suffix}` : date
  }

  const date = `${localeDate(start)} - ${localeDate(endDate.toISO())}`
  return suffix ? `${date} ${suffix}` : date
}

export function formatCountdown({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}): string {
  if (days > 0) {
    return 'd วัน hh ชม. mm นาที ss วินาที'
  }
  if (hours > 0) {
    return 'hh ชม. mm นาที ss วินาที'
  }
  if (minutes > 0) {
    return 'mm นาที ss วินาที'
  }
  if (seconds > 0) {
    return 'ss วินาที'
  }
  return ''
}
