import { plainToInstance } from 'class-transformer'

import { CREATE_USER_READING } from '@client/collections/UserReading/schemas/createUserReading'
import { CREATE_USER_READINGS } from '@client/collections/UserReading/schemas/createUserReadings'
import { GET_PREVIOUS_AND_NEXT_BOOK_ID } from '@client/collections/UserReading/schemas/getPreviousAndNextBookId'
import { READ_NOW } from '@client/collections/UserReading/schemas/readNow'
import { REMOVE_USER_READING } from '@client/collections/UserReading/schemas/removeUserReading'
import { USER_READINGS } from '@client/collections/UserReading/schemas/userReadings'
import { gqlApiInstance } from '@client/init'
import { PreviousAndNextBookIdType } from '@models/userReading/PreviousAndNextBookIdType'
import { ReadNowType } from '@models/userReading/ReadNowType'
import { ContinueToReadResponse } from '@models/userReading/ContinueToReadResponse'
import {
  UserGuestReadingType,
  UserReadingType,
} from '@models/userReading/UserReadingType'
import { useBookAction } from '@hooks/book/useBookAction'
import { useChapterAction } from '@hooks/chapter/useChapterAction'

export function useUserReadingAction() {
  const bookClient = useBookAction()
  const bookChapterClient = useChapterAction()

  async function userReadings(params: {
    page: number
    limit: number
    sort: string
  }): Promise<ContinueToReadResponse> {
    const res = await gqlApiInstance.request(USER_READINGS, {
      limitPerPage: params.limit,
      page: params.page,
      sort: params.sort,
    })
    return plainToInstance(ContinueToReadResponse, res.userReadings)
  }

  async function createUserReadings(
    items: { chapterPageId: number; readingPercentage?: number }[]
  ) {
    const res = await gqlApiInstance.request(CREATE_USER_READINGS, {
      createUserReadingInput: items?.map(item => ({
        chapterPageId: item.chapterPageId,
        ...(item.readingPercentage
          ? { readingPercentage: item.readingPercentage }
          : {}),
      })),
    })

    return res.createUserReadings
  }

  async function createUserReading(
    chapterPageId: number,
    readingPercentage?: number
  ): Promise<{ id: number; updatedAt: string }> {
    const res = await gqlApiInstance.request(CREATE_USER_READING, {
      createUserReadingInput: {
        chapterPageId,
        ...(readingPercentage ? { readingPercentage } : {}),
      },
    })

    return res.createUserReading
  }

  async function removeUserReading(id: number): Promise<any> {
    const res = await gqlApiInstance.request(REMOVE_USER_READING, {
      id,
    })
    return res.removeUserReading
  }

  async function readNow(
    bookId: number,
    bookChapterId?: number
  ): Promise<ReadNowType> {
    const res = await gqlApiInstance.request(READ_NOW, {
      bookId,
      bookChapterId,
    })
    return plainToInstance(ReadNowType, res.readNow)
  }

  async function getPreviousAndNextBookId(
    id: number
  ): Promise<PreviousAndNextBookIdType> {
    const res = await gqlApiInstance.request(GET_PREVIOUS_AND_NEXT_BOOK_ID, {
      id,
    })
    return res.getPreviousAndNextBookId
  }

  async function replaceGuestReading(
    books?: (UserReadingType | UserGuestReadingType)[]
  ): Promise<void> {
    if (typeof window === 'undefined') return

    // Remove old key
    window.localStorage.removeItem('userReading')

    if (books?.length) {
      window.localStorage.setItem('userGuestReading', JSON.stringify(books))
      return
    }
    window.localStorage.removeItem('userGuestReading')
  }

  function getRawGuestReading(): UserGuestReadingType[] {
    if (typeof window === 'undefined') return []

    // Remove old key
    window.localStorage.removeItem('userReading')

    const userReadingLocal = window.localStorage.getItem('userGuestReading')

    if (userReadingLocal) {
      const baseData = JSON.parse(userReadingLocal) as UserGuestReadingType[]

      baseData.sort((a, b) => {
        return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
      })

      return baseData
    }

    return []
  }

  async function getGuestReading(raw = false): Promise<UserReadingType[]> {
    if (typeof window === 'undefined') return []

    // Remove old key
    window.localStorage.removeItem('userReading')

    const userReadingLocal = window.localStorage.getItem('userGuestReading')

    if (userReadingLocal) {
      const baseData = JSON.parse(userReadingLocal) as UserReadingType[]

      if (raw) {
        return baseData
      }

      const res = await bookClient.getStoryReaderByIds(
        baseData.map(row => row.bookId)
      )

      if (res?.length) {
        const chapters = await bookChapterClient.bookChapters(
          baseData.map(d => d.chapterId),
          baseData.length,
          1
        )

        return baseData.map(row => {
          const book = res.find(r => r.id === row.bookId)
          const chapter = chapters.find(c => c.id === row.chapterId)

          if (book && chapter) {
            return {
              ...row,
              chapterName: chapter.chapterName,
              bookTitle: book.title,
              coverImgPath: book.coverImgPath,
              bookWriter: book.penName?.firstPenName,
              profilePageSlug: book.penName?.user?.profilePageSlug,
            }
          }

          return row
        })
      }
    }

    return []
  }

  return {
    userReadings,
    createUserReading,
    createUserReadings,
    removeUserReading,
    readNow,
    getPreviousAndNextBookId,
    getGuestReading,
    replaceGuestReading,
    getRawGuestReading,
  }
}
