import { BookEnum } from '@interfaces/BookEnum'

class BookType {
  writer: string

  title: string

  bookType: BookEnum
}

class ChapterPageType {
  id: number

  imgPath: string

  content: string

  ordered: number

  size: number | null
}

export class ChapterType {
  id: number

  chapterTitle: string

  chapterName: string

  chapterCoverImgPath: string

  price: number

  status: string

  editedStatus: string

  book: BookType

  updatedAt?: string

  writerMessage: string

  chapterPages: ChapterPageType[]

  publishedAt: string | null
}
