import { gql } from 'graphql-request'

export const USER_READINGS = gql`
  query UserReadings($limitPerPage: Int, $page: Int, $sort: String) {
    userReadings(limitPerPage: $limitPerPage, page: $page, sort: $sort) {
      total
      page
      data {
        chapter {
          id
          chapterName
          status
        }
        book {
          id
          title
          writer
          status
          coverImgPath
          coverResizeImgPath
          penName {
            user {
              id
              profilePageSlug
            }
            firstPenName
          }
        }
        id
        updatedAt
        readingPercentage
      }
    }
  }
`
