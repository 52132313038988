import { gql } from 'graphql-request'

export const BLUK_UPDATE_BOOK_CHAPTER = gql`
  mutation BulkUpdateBookChapter(
    $bulkUpdateBookChapterInput: BulkUpdateBookChapterInput!
  ) {
    bulkUpdateBookChapter(
      bulkUpdateBookChapterInput: $bulkUpdateBookChapterInput
    )
  }
`
