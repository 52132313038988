import { plainToInstance, instanceToPlain } from 'class-transformer'
import { DateTime } from 'luxon'

import {
  BOOK_CHAPTER,
  BOOK_CHAPTER_PREVIEW,
  BOOK_CHAPTER_READER,
  GET_BOOK_FOR_CREATE,
} from '@client/collections/Chapter/schemas/bookChapter'
import { BOOK_CHAPTER_META_DATA } from '@client/collections/Chapter/schemas/bookChapterMetaData'
import {
  BOOK_CHAPTERS_READER_PAGINATE,
  BOOK_CHAPTERS,
  BOOK_CHAPTER_OPTIONS,
  GET_CHAPTER_LIST,
  BUY_ALL_BOOK_CHAPTERS_READER,
} from '@client/collections/Chapter/schemas/bookChapters'
import { BOOK_CHAPTER_WITH_LOG } from '@client/collections/Chapter/schemas/bookChapterWithLog'
import { BLUK_UPDATE_BOOK_CHAPTER } from '@client/collections/Chapter/schemas/bulkUpdateBookChapter'
import { BUY_BOOK_CHAPTERS } from '@client/collections/Chapter/schemas/buyBookChapters'
import { BUY_BOOK_PROMOTION } from '@client/collections/Chapter/schemas/buyBook'
import { CREATE_BOOK_CHAPTER } from '@client/collections/Chapter/schemas/createBookChapterInput'
import { GET_TABLE_CONTENT_READER_LIST } from '@client/collections/Chapter/schemas/getTableContentReaderList'
import { INCREMENT_VIEW } from '@client/collections/Chapter/schemas/IncrementView'
import {
  PUBLISH_BOOK_CHAPTERS_ONCE,
  PUBLISH_BOOK_CHAPTERS_GRADUALLY,
} from '@client/collections/Chapter/schemas/publishBookChapters'
import { REMOVE_BOOK_CHAPTER } from '@client/collections/Chapter/schemas/removeBookChapterInput'
import { REVERT_BOOK_CHAPTER } from '@client/collections/Chapter/schemas/revertBookChapter'
import { UPDATE_BOOK_CHAPTER } from '@client/collections/Chapter/schemas/updateBookChapterInput'
import { UPDATE_BOOK_CHAPTER_STATUS } from '@client/collections/Chapter/schemas/updateBookChapterStatus'
import { CHECK_BOOK_BUY_ALL_PROMOTION } from '@client/collections/Chapter/schemas/getBookBuyAllChaptersPromotionDetail'
import { GET_BOOK_READ_PROMOTION_DETAIL } from '@client/collections/Chapter/schemas/getBookReadPromotionDetail'
import { UPDATE_BOOK_BUY_ALL_PROMOTION } from '@client/collections/Chapter/schemas/updateBookBuyAllChaptersPromotion'
import { UPDATE_BOOK_READ_PROMOTION } from '@client/collections/Chapter/schemas/updateBookReadPromotion'
import { gqlApiInstance } from '@client/init'
import { BookEnum } from '@interfaces/BookEnum'
import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import {
  PromotionBuyAllEnum,
  PromotionReadFreeEnum,
} from '@interfaces/PromotionTypeEnum'
import { clearComma } from '@lib/utils'
import { BookChapterType } from '@models/book/BookChapterType'
import { BookChapterMetaDataType } from '@models/chapter/BookChapterMetaData'
import { BookChapterPreviewType } from '@models/chapter/BookChapterPreviewType'
import { BookType } from '@models/chapter/BookType'
import { BuyChapterFormType } from '@models/chapter/BuyChapterFormType'
import { BuyAllChaptersFormType } from '@models/chapter/BuyAllChaptersFormType'
import { ChapterOptionResponse } from '@models/chapter/ChapterOptionResponse'
import { ChapterType } from '@models/chapter/ChapterType'
import { ChapterWithLogsType } from '@models/chapter/ChapterWithLogsType'
import { ReadingChapterType } from '@models/chapter/ReadingChapterType'
import { ChapterFormType } from '@models/myWriting/ChapterFormType'
import { ChapterPageType } from '@models/myWriting/ChapterPageType'
import { TableContentReaderResponse } from '@models/chapter/TableContentReaderResponse'
import { BookBuyAllPromotionType } from '@models/chapter/BookBuyAllPromotionType'
import { BookReadFreePromotionDetailType } from '@models/chapter/BookReadFreePromotionDetailType'
import { UserCoin } from '@models/user/UserCoin'
import { ChapterReaderType } from '@models/chapter/ChapterReaderType'
import { BuyAllBookChapterReaderType } from '@models/chapter/BuyAllBookChapterReaderType'
import { TableContentReaderType } from '@models/chapter/TableContentReaderType'
import { BookPromotionSalesType } from '@models/chapter/BookPromotionSalesType'
import { ACTIVE_PROMOTION_SALES } from '@client/collections/Chapter/schemas/getActivePromotionSales'
import { PRICE_FOR_BUY_BOOK } from '@client/collections/Chapter/schemas/priceForBuyBook'

export function useChapterAction() {
  async function bookChapter(id: number): Promise<ChapterType> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER, {
      id,
    })

    const response: ChapterType = { ...res.bookChapter }
    if (response.book.bookType === BookEnum.MANGA) {
      const noFileSizeData = response.chapterPages.filter(
        row => row.size === null
      )
      if (noFileSizeData.length) {
        const fileSizeResponse = await Promise.all(
          noFileSizeData.map((row: ChapterPageType) =>
            fetch(row.imgPath, {
              cache: 'no-cache',
              method: 'HEAD',
            })
          )
        )

        noFileSizeData.forEach((row, index) => {
          const chapterPageIndex = response.chapterPages.findIndex(
            chapterPage => chapterPage.id === row.id
          )
          response.chapterPages[chapterPageIndex].size = Number(
            fileSizeResponse[index].headers.get('content-length')
          )
        })
      }
    }

    return plainToInstance(ChapterType, res.bookChapter)
  }

  async function bookChapterPreview(
    id: number
  ): Promise<BookChapterPreviewType> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER_PREVIEW, {
      id,
    })

    return plainToInstance(BookChapterPreviewType, res.bookChapterWriter)
  }

  async function bookChapterReader(
    id: number
  ): Promise<BookChapterPreviewType> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER_READER, {
      id,
    })

    return plainToInstance(BookChapterPreviewType, res.bookChapter)
  }

  async function bookChapterMetaData(
    id: number
  ): Promise<BookChapterMetaDataType> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER_META_DATA, {
      id,
    })

    return plainToInstance(BookChapterMetaDataType, res.bookChapterForShare)
  }

  async function getBuyAllBookChaptersReader(
    bookId: number
  ): Promise<BuyAllBookChapterReaderType[]> {
    const res = await gqlApiInstance.request(BUY_ALL_BOOK_CHAPTERS_READER, {
      bookId,
      onlyPaid: true,
    })

    return plainToInstance(
      BuyAllBookChapterReaderType,
      res.getBookChaptersReader as []
    )
  }

  async function bookChaptersReaderPaginate(
    bookId: number,
    page: number
  ): Promise<ChapterReaderType[]> {
    const res = await gqlApiInstance.request(BOOK_CHAPTERS_READER_PAGINATE, {
      bookId,
      page,
      limitPerPage: 50,
      onlyPaid: false,
    })

    return plainToInstance(
      ChapterReaderType,
      res.getBookChaptersReaderPaginateWithSummaryPrice.data as []
    )
  }

  async function bookChapters(
    ids: number[],
    limitPerPage: number,
    page: number
  ): Promise<ReadingChapterType[]> {
    const res = await gqlApiInstance.request(BOOK_CHAPTERS, {
      ids,
      page,
      limitPerPage,
    })
    return res.bookChapters.data
  }

  async function bookChapterOptions(
    bookId: number,
    page: number,
    limitPerPage: number
  ): Promise<ChapterOptionResponse> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER_OPTIONS, {
      bookId,
      page,
      limitPerPage,
    })

    return plainToInstance(ChapterOptionResponse, res.getBookChapters)
  }

  async function book(id: number): Promise<BookType> {
    const { getBookForCreate } = await gqlApiInstance.request(
      GET_BOOK_FOR_CREATE,
      {
        id,
      }
    )

    return plainToInstance(BookType, getBookForCreate)
  }

  async function createBookChapter(
    form: ChapterFormType
  ): Promise<{ id: number }> {
    const res = await gqlApiInstance.request(CREATE_BOOK_CHAPTER, {
      createBookChapterInput: instanceToPlain(form, {
        excludeExtraneousValues: true,
      }),
    })
    return res.createBookChapter
  }

  async function updateBookChapter(form: ChapterFormType): Promise<any> {
    const res = await gqlApiInstance.request(UPDATE_BOOK_CHAPTER, {
      updateBookChapterInput: instanceToPlain(form, {
        excludeExtraneousValues: true,
      }),
    })

    return res.updateBookChapter
  }

  async function removeBookChapter(ids: number[]) {
    const res = await gqlApiInstance.request(REMOVE_BOOK_CHAPTER, {
      ids,
    })

    return res.bulkRemoveBookChapter
  }

  async function bookChapterWithLogs(id: number): Promise<ChapterWithLogsType> {
    const res = await gqlApiInstance.request(BOOK_CHAPTER_WITH_LOG, {
      bookChapterId: id,
    })
    return res.bookChapter
  }

  async function revertBookChapter(id: number): Promise<any> {
    return gqlApiInstance.request(REVERT_BOOK_CHAPTER, {
      revertBookChapterId: id,
    })
  }

  async function updateBookChapterStatus({
    id,
    bookId,
    status,
    publishedAt,
  }: {
    id: number
    bookId: number
    status: ChapterStatusEnum
    publishedAt?: DateTime
  }): Promise<BookChapterType> {
    const res = await gqlApiInstance.request(UPDATE_BOOK_CHAPTER_STATUS, {
      updateBookChapterInput: {
        id,
        bookId,
        status,
        ...(publishedAt && { publishedAt: publishedAt.toUTC() }),
      },
    })

    return plainToInstance(BookChapterType, res.updateBookChapter)
  }

  async function bulkUpdateBookChapter({
    ids,
    bookId,
    status,
    publishedAt,
  }: {
    ids: number[]
    bookId: number
    status: string
    publishedAt?: DateTime
  }) {
    await gqlApiInstance.request(BLUK_UPDATE_BOOK_CHAPTER, {
      bulkUpdateBookChapterInput: {
        ids,
        bookId,
        status,
        ...(publishedAt && { publishedAt: publishedAt.toUTC() }),
      },
    })
  }

  async function updateBookChapterPrice({
    id,
    price,
  }: {
    id: number
    price: number
  }): Promise<{ id: number; price: number }> {
    const res = await gqlApiInstance.request(UPDATE_BOOK_CHAPTER, {
      updateBookChapterInput: {
        id,
        price,
      },
    })

    return res.updateBookChapter
  }

  async function updateChapterPriceList({
    ids,
    price,
  }: {
    ids: number[]
    price: number
  }): Promise<void> {
    await gqlApiInstance.request(BLUK_UPDATE_BOOK_CHAPTER, {
      bulkUpdateBookChapterInput: {
        ids,
        price,
      },
    })
  }

  async function buyChapters(
    { chapterIds, bookId, goldCoin, silverCoin, thirdCoin }: BuyChapterFormType,
    userCoin: UserCoin
  ): Promise<string> {
    const coins = []
    const gold = clearComma(goldCoin)
    const silver = clearComma(silverCoin)
    const third = thirdCoin ? clearComma(thirdCoin) : 0

    if (gold) {
      coins.push({
        coinId: userCoin.paidCoin?.id,
        coinValue: gold,
        coinType: userCoin.paidCoin?.type,
      })
    }

    if (silver) {
      coins.push({
        coinId: userCoin.freeCoin?.id,
        coinValue: silver,
        coinType: userCoin.freeCoin?.type,
      })
    }

    if (third) {
      coins.push({
        coinId: userCoin.thirdCoin?.id,
        coinValue: third,
        coinType: userCoin.thirdCoin?.type,
      })
    }

    const res = await gqlApiInstance.request(BUY_BOOK_CHAPTERS, {
      buyBookChaptersInput: {
        bookChapterIds: chapterIds.map(id => Number(id)),
        bookId,
        coins,
      },
    })

    return res.buyBookChapters?.transactionId
  }

  async function buyAllChaptersPromotion(
    { bookId, goldCoin, silverCoin, thirdCoin }: BuyAllChaptersFormType,
    userCoin: UserCoin
  ) {
    const coins = []
    const gold = clearComma(goldCoin)
    const silver = clearComma(silverCoin)
    const third = thirdCoin ? clearComma(thirdCoin) : 0

    if (gold) {
      coins.push({
        coinId: userCoin.paidCoin?.id,
        coinValue: gold,
        coinType: userCoin.paidCoin?.type,
      })
    }

    if (silver) {
      coins.push({
        coinId: userCoin.freeCoin?.id,
        coinValue: silver,
        coinType: userCoin.freeCoin?.type,
      })
    }

    if (third) {
      coins.push({
        coinId: userCoin.thirdCoin?.id,
        coinValue: third,
        coinType: userCoin.thirdCoin?.type,
      })
    }

    await gqlApiInstance.request(BUY_BOOK_PROMOTION, {
      buyBookInput: {
        bookId,
        coins,
      },
    })
  }

  async function publishBookChaptersOnce(
    chapterIds: number[],
    publishedAt: DateTime
  ) {
    await gqlApiInstance.request(PUBLISH_BOOK_CHAPTERS_ONCE, {
      publishChaptersOnceInput: {
        chapterIds,
        publishedAt: publishedAt.toUTC(),
      },
    })
  }

  async function publishBookChaptersGradually(
    chapterIds: number[],
    publishedAt: DateTime,
    interval: number
  ): Promise<void> {
    await gqlApiInstance.request(PUBLISH_BOOK_CHAPTERS_GRADUALLY, {
      publishChaptersGraduallyInput: {
        chapterIds,
        initialPublishedAt: publishedAt.toUTC(),
        interval,
      },
    })
  }

  async function incrementView(params: {
    chapterId?: number
    bookId?: number
    ebookId?: number
  }): Promise<void> {
    await gqlApiInstance.request(INCREMENT_VIEW, {
      bookChapterId: params.chapterId,
      bookId: params.bookId,
      ebookId: params.ebookId,
    })
  }

  async function getTableContenReadertList(
    bookId: number,
    page: number,
    limitPerPage: number
  ): Promise<TableContentReaderResponse> {
    const res = await gqlApiInstance.request(GET_TABLE_CONTENT_READER_LIST, {
      bookId,
      page,
      limitPerPage,
    })

    return plainToInstance(
      TableContentReaderResponse,
      res.getBookChaptersReaderPaginate
    )
  }

  async function getChapters(
    bookId: number,
    page: number
  ): Promise<BookChapterType[]> {
    const res = await gqlApiInstance.request(GET_CHAPTER_LIST, {
      bookId,
      limitPerPage: 50,
      page,
    })

    return plainToInstance(BookChapterType, res.getBookChapters.data as [])
  }

  async function getBookBuyAllPromotion(
    bookId: number
  ): Promise<BookBuyAllPromotionType> {
    try {
      const { getBookBuyAllChaptersPromotionDetail } =
        await gqlApiInstance.request(CHECK_BOOK_BUY_ALL_PROMOTION, {
          bookId,
        })

      return plainToInstance(
        BookBuyAllPromotionType,
        getBookBuyAllChaptersPromotionDetail
      )
    } catch (error) {
      return plainToInstance(BookBuyAllPromotionType, null)
    }
  }

  async function getBookReadFreePromotionDetail(
    bookId: number
  ): Promise<BookReadFreePromotionDetailType> {
    try {
      const { getBookReadPromotionDetail } = await gqlApiInstance.request(
        GET_BOOK_READ_PROMOTION_DETAIL,
        {
          bookId,
        }
      )

      return plainToInstance(
        BookReadFreePromotionDetailType,
        getBookReadPromotionDetail
      )
    } catch (error) {
      return plainToInstance(BookReadFreePromotionDetailType, null)
    }
  }

  async function updateBookBuyAllPromotion({
    bookId,
    startDate,
    endDate,
    discountType,
    discountValue,
  }: {
    bookId: number
    startDate: DateTime
    endDate: DateTime
    discountType: PromotionBuyAllEnum
    discountValue: number
  }): Promise<void> {
    await gqlApiInstance.request(UPDATE_BOOK_BUY_ALL_PROMOTION, {
      updateBookBuyAllChaptersPromotionInput: {
        bookId,
        startDate,
        endDate,
        discountType,
        discountValue,
      },
    })
  }

  async function updateBookReadFreePromotion({
    bookId,
    startDate,
    endDate,
    readPromotionType,
    value,
  }: {
    bookId: number
    startDate: DateTime
    endDate: DateTime
    readPromotionType: PromotionReadFreeEnum
    value?: number
  }): Promise<void> {
    await gqlApiInstance.request(UPDATE_BOOK_READ_PROMOTION, {
      updateBookReadPromotionInput: {
        bookId,
        startDate,
        endDate,
        readPromotionType,
        ...(readPromotionType === PromotionReadFreeEnum.READ_FREE_PER_DAY && {
          readFreePerDay: value,
        }),
        ...(readPromotionType ===
          PromotionReadFreeEnum.READ_FREE_WITHIN_DAY && {
          readFreeWithinDay: value,
        }),
        ...(readPromotionType === PromotionReadFreeEnum.READ_FREE_INTERVAL && {
          readFreeIntervalStart: startDate,
          readFreeIntervalEnd: endDate,
        }),
      },
    })
  }

  async function getChapterForContinueToRead(
    bookId: number
  ): Promise<TableContentReaderType | undefined> {
    const res = await gqlApiInstance.request(GET_TABLE_CONTENT_READER_LIST, {
      bookId,
      page: 1,
      limitPerPage: 1,
    })

    if (res.getBookChaptersReaderPaginate.data.length) {
      return plainToInstance(
        TableContentReaderType,
        res.getBookChaptersReaderPaginate.data[0]
      )
    }

    return undefined
  }

  async function getActivePromotionSales(
    bookId: number
  ): Promise<BookPromotionSalesType[]> {
    const { activePromotionSales } = await gqlApiInstance.request(
      ACTIVE_PROMOTION_SALES,
      {
        bookId,
      }
    )
    return plainToInstance(BookPromotionSalesType, activePromotionSales as [])
  }

  async function getAllPriceForBuyBook(bookId: number): Promise<number> {
    const { priceForBuyBook } = await gqlApiInstance.request(
      PRICE_FOR_BUY_BOOK,
      {
        bookId,
      }
    )

    return priceForBuyBook
  }

  return {
    bookChapter,
    bookChapterPreview,
    bookChapterReader,
    bookChapterMetaData,
    getBuyAllBookChaptersReader,
    bookChapters,
    bookChapterOptions,
    book,
    createBookChapter,
    updateBookChapter,
    removeBookChapter,
    bookChapterWithLogs,
    revertBookChapter,
    updateBookChapterStatus,
    bulkUpdateBookChapter,
    updateBookChapterPrice,
    updateChapterPriceList,
    buyChapters,
    buyAllChaptersPromotion,
    publishBookChaptersOnce,
    publishBookChaptersGradually,
    incrementView,
    getTableContenReadertList,
    getChapters,
    getBookBuyAllPromotion,
    getBookReadFreePromotionDetail,
    updateBookBuyAllPromotion,
    updateBookReadFreePromotion,
    bookChaptersReaderPaginate,
    getChapterForContinueToRead,
    getActivePromotionSales,
    getAllPriceForBuyBook,
  }
}
