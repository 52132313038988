import React, { useCallback, useState } from 'react'
import { DateTime } from 'luxon'
import { useQuery, useQueryClient } from 'react-query'
import { useRouter } from 'next/router'

import { useAlert } from '@hooks/useAlert'
import { TopChartEnum } from '@interfaces/TopChartEnum'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { useWindowSize } from '@hooks/useWindowSize'
import { TopChartCharacterType } from '@models/character/TopChartCharacterType'
import { SupportCharacterFormType } from '@components/SupportCharacterModal/interface'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'
import { useCharacterAction } from '@hooks/character/useCharacterAction'
import { WithTopChartSectionProps, TopChartSectionProps } from './interface'

export function withTopChartSection(Component: React.FC<TopChartSectionProps>) {
  function WithTopChartSection(props: WithTopChartSectionProps) {
    const { isDesktop } = useWindowSize()
    const dt = DateTime.local()
    const router = useRouter()
    const [monthSelected, setMonthSelected] = useState(dt)
    const query = {
      topChartsType: TopChartEnum.MONTH,
      startDate: monthSelected.startOf('month'),
      endDate: monthSelected.endOf('month'),
      limit: 20,
    }
    const characterClient = useCharacterAction()
    const queryClient = useQueryClient()
    const { user, userCoin, isAuthenticated } = useAuthentication()
    const alert = useAlert()
    const supportCharacterModal = useModal({ modal: 'supportCharacter' }, true)
    const loginModal = useModal({ modal: 'login' })
    const notEnoughCoinModal = useModal({
      modal: 'notEnoughCoin',
      modalProps: {
        describeText: '',
        purposeText: 'สนับสนุนตัวละคร',
      },
    })
    const changeEmailModal = useModal({ modal: 'changeEmail' }, true)

    const { data: months } = useQuery('character-chart-exist-date', () =>
      characterClient.getMonths()
    )

    const { data, refetch, isLoading } = useQuery(
      ['top-chart-character', query],
      () => characterClient.getTopChartCharacters(query),
      {
        cacheTime: 0,
      }
    )

    const handleMonthChange = useCallback((value: string) => {
      setMonthSelected(DateTime.fromFormat(value, 'LLLL yyyy'))
    }, [])

    function supportCharacter(character: TopChartCharacterType) {
      if (
        (userCoin.freeCoin && userCoin.freeCoin.value >= 1) ||
        (userCoin.paidCoin && userCoin.paidCoin.value >= 1) ||
        (userCoin.thirdCoin && userCoin.thirdCoin.value >= 1)
      ) {
        supportCharacterModal.show({
          title: character.bookCharacter.book.title,
          description: character.bookCharacter.description,
          name: character.bookCharacter.name,
          roleName: character.bookCharacter.characterRole?.name,
          imgPath: character.bookCharacter.imgPath,
          onSubmit: async ({
            freeCoin,
            paidCoin,
            thirdCoin,
          }: SupportCharacterFormType) => {
            try {
              const newData = await characterClient.createCharacterDonation(
                character.bookCharacter.id,
                userCoin,
                freeCoin,
                paidCoin,
                thirdCoin
              )
              if (newData) {
                characterClient.triggerDonationCharacter(
                  {
                    freeCoin,
                    paidCoin,
                    thirdCoin,
                  },
                  newData.transactionId,
                  {
                    name: character.bookCharacter.name,
                    id: character.bookCharacter.id,
                  }
                )
                queryClient.invalidateQueries('userCoin')
                alert.success(
                  `คุณได้สนับสนุนตัวละคร ${character.bookCharacter.name} เรียบร้อยแล้ว`
                )
                refetch()
              }
            } catch (_) {
              alert.error(
                'เกิดข้อผิดพลาด สนับสนุนตัวละครไม่สำเร็จ กรุณาลองอีกครั้ง'
              )
            } finally {
              supportCharacterModal.hide()
            }
          },
          onCancel: () => {
            supportCharacterModal.hide()
          },
        })
      } else {
        notEnoughCoinModal.show()
      }
    }

    const handleSupportCharacter = useCallback(
      (character: TopChartCharacterType) => {
        if (isAuthenticated) {
          if (user.emailEditable) {
            changeEmailModal.show({
              onSuccess: () => {
                supportCharacter(character)
              },
            })
          } else {
            supportCharacter(character)
          }
        } else {
          loginModal.show()
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [userCoin, isAuthenticated]
    )

    const handleGoToStoryDetail = useCallback(
      (value: TopChartCharacterType) => {
        if (
          value.bookCharacter.book.status !== BookStatusEnum.PUBLISHED ||
          value.bookCharacter.book.deletedAt ||
          !value.bookCharacter.visible ||
          !!value.bookCharacter.deletedAt
        ) {
          alert.warning('ตัวละคร/หนังสือเรื่องนี้ไม่เผยแพร่/ถูกลบแล้ว')
          return
        }

        router.push(`/story/${value.bookCharacter.book.id}`)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const componentProps = {
      ...props,
      isLoading,
      data,
      months,
      monthSelected: monthSelected.toFormat('LLLL yyyy'),
      canSupport: dt.monthLong === monthSelected.monthLong,
      isDesktop,
      handleMonthChange,
      handleSupportCharacter,
      handleGoToStoryDetail,
    }

    return <Component {...componentProps} />
  }

  return WithTopChartSection
}
