import { gql } from 'graphql-request'

export const CHECK_BOOK_BUY_ALL_PROMOTION = gql`
  query GetBookBuyAllChaptersPromotionDetail($bookId: Int!) {
    getBookBuyAllChaptersPromotionDetail(bookId: $bookId) {
      id
      title
      startDate
      endDate
      discountType
      discountValue
      discountPrice
      afterDiscountPrice
      summaryPriceBeforeDiscount
      bookChapterCount
    }
  }
`
