import { gql } from 'graphql-request'

export const UPDATE_BOOK_CHAPTER = gql`
  mutation UpdateBookChapter($updateBookChapterInput: UpdateBookChapterInput!) {
    updateBookChapter(updateBookChapterInput: $updateBookChapterInput) {
      id
      price
    }
  }
`
