import { ChapterStatusEnum } from '@interfaces/ChapterStatusEnum'
import { Expose, Transform } from 'class-transformer'

export class BookChapterType {
  @Expose() id: number

  @Expose({ name: 'runningNo' }) order: number

  @Expose() chapterName: string

  @Expose() chapterTitle: string

  @Expose() viewCount: number

  @Expose() commentCount: number

  @Expose() price: number

  @Expose()
  status: ChapterStatusEnum

  @Expose() chapterCoverImgPath: string

  @Expose() publishedAt: string

  @Expose()
  @Transform(({ obj }) => {
    const datetime = new Date().valueOf()
    const publishedAt = new Date(obj.publishedAt).valueOf()

    return obj.status === ChapterStatusEnum.PUBLISHED && datetime < publishedAt
  })
  showPublishedAt: boolean

  @Expose()
  purchasedCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  totalSales: number
}
