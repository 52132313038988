import { gql } from 'graphql-request'

export const GET_TABLE_CONTENT_READER_LIST = gql`
  query GetTableContentReaderList(
    $limitPerPage: Int
    $page: Int
    $bookId: Int!
  ) {
    getBookChaptersReaderPaginate(
      limitPerPage: $limitPerPage
      page: $page
      bookId: $bookId
    ) {
      data {
        id
        chapterName
        chapterTitle
        status
        isPaid
      }
      page
    }
  }
`
