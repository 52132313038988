import { gql } from 'graphql-request'

export const PUBLISH_BOOK_CHAPTERS_GRADUALLY = gql`
  mutation PublishBookChaptersGradually(
    $publishChaptersGraduallyInput: PublishChaptersGraduallyInput!
  ) {
    publishBookChaptersGradually(
      publishChaptersGraduallyInput: $publishChaptersGraduallyInput
    ) {
      message
    }
  }
`

export const PUBLISH_BOOK_CHAPTERS_ONCE = gql`
  mutation PublishBookChaptersOnce(
    $publishChaptersOnceInput: PublishChaptersOnceInput!
  ) {
    publishBookChaptersOnce(
      publishChaptersOnceInput: $publishChaptersOnceInput
    ) {
      message
    }
  }
`
